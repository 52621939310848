import React, {Fragment} from "react";
import ModalFormInputsContainer from "./ModalFormInputsContainer";
import ModalFormButtons from "./ModalFormButtons";

const ModalForm = (props) => {
    return (
        <Fragment>
            <ModalFormInputsContainer title={props.title}>
                {props.children}
            </ModalFormInputsContainer>
            <ModalFormButtons
                saveTitle={props.saveButtonTitle}
                handleCancelClick={props.handleCancelButtonClick}
            />
        </Fragment>
    );
}

export default ModalForm;