import {API_URL, getCookie, handleErrorResponse} from "../utility/utility";

export async function login(email, password) {
    // Call the `sanctum/csrf-cookie` route to get the CSRF token cookie
    await fetch(`${API_URL}/sanctum/csrf-cookie`, {
        method      : 'GET',
        credentials : 'include'
    });

    const response = await fetch(`${API_URL}/api/login`, {
        method      : 'POST',
        body        : JSON.stringify({
            email    : email,
            password : password,
        }),
        headers     : {
            'Accept'       : 'application/json',
            'Content-Type' : 'application/json',
            'X-XSRF-TOKEN' : getCookie('XSRF-TOKEN'),
        },
        credentials : 'include',
    });

    if (!response.ok) {
        throw {message : 'Failed to login user', status : 500};
    }

    return response.json();
}

export async function getAuthenticatedUser() {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/user`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    if (!response.ok) {
        throw {message : 'Failed to fetch user', status : 500};
    }

    return response.json();
}

export async function findUsersWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/users?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch users');

    return response.json();
}

export async function getUser(userId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/users/${userId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to get user');

    return response.json();
}

export async function createUser(name, email, password, passwordConfirmation, userType) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/users`, {
        method  : 'POST',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        body    : JSON.stringify({
            name                  : name,
            email                 : email,
            password              : password,
            password_confirmation : passwordConfirmation,
            userType              : userType
        })
    });

    await handleErrorResponse(response, 'Failed to create user');

    return response.json();
}

export async function findUserTypes() {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/user-types`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch user types');

    return response.json();
}

export async function updateUser(userId, name, email, userType) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/users/${userId}`, {
        method  : 'PATCH',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        body    : JSON.stringify({
            name     : name,
            email    : email,
            userType : userType
        })
    });

    await handleErrorResponse(response, 'Failed to update user');
}

export async function changePassword(userId, password, passwordConfirmation) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/users/${userId}/password`, {
        method  : 'PATCH',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        body    : JSON.stringify({
            password              : password,
            password_confirmation : passwordConfirmation
        })
    });

    await handleErrorResponse(response, 'Failed to change password');
}