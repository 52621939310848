import {Fragment, useState} from 'react';
import React from "react";
import ListEdit from "../UI/ListEdit";
import Box from "../UI/Box";
import {useUserAssignments} from "../../hooks/useUserAssignments";
import {deleteUserAssignment, findNotAssignedCustomers, findThematics, getUserAssignment} from "../../api/userAssignmentApiClient";
import Button from "../UI/Button";
import UserAssignmentModal from "./UserAssignmentModal";
import {toast} from "react-toastify";

const UserAssignmentList = (props) => {
    // Modal
    const [showModal, setShowModal] = useState(false);
    const [customers, setCustomers] = useState(null);
    const [defaultModalCustomerValue, setDefaultModalCustomerValue] = useState(null);
    const [thematics, setThematics] = useState(null);
    // Create button
    const [isUpdate, setIsUpdate] = useState(false);
    // ListEdit
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [submitCount, setSubmitCount] = useState(0);
    const [userAssignment, setUserAssignment] = useState([]);
    const {userAssignments, pagination} = useUserAssignments(props.userId, page, pageSize, submitCount);

    const userAssignmentList = userAssignments.map((item) => {
        const thematicNames = item.thematics.map(function (thematic, index) {
            return thematic.name;
        }).join(', ');

        return {
            userId       : props.userId,
            customerId   : item.customer.customerId,
            customerName : item.customer.firstName + ' ' + item.customer.lastName,
            thematics    : thematicNames
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const listHeaders = [
        'Customer',
        'Thematics',
        'Actions'
    ];

    const columns = {
        functionParams : ['userId', 'customerId'],
        dataKeys       : [
            'customerName',
            'thematics'
        ],
    }

    const handleAddSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    const handleEditSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    // Create button
    const handleAddCustomerButtonClick = (defaultModalCustomerId) => {
        setIsUpdate(false);
        const customers = findNotAssignedCustomers(props.userId);

        customers
            .then(
                (data) => {
                    const customers = data;
                    const thematics = findThematics();

                    thematics
                        .then(
                            (data) => {
                                setThematics(data);
                                setCustomers(customers)
                                setDefaultModalCustomerValue(defaultModalCustomerId ?? null)
                                setShowModal(true)
                            }
                        )
                        .catch(
                            (error) => console.log(error)
                        );
                }
            )
            .catch(
                (error) => console.log(error)
            );

    }

    const handleEditIconClick = (userId, customerId) => {
        setIsUpdate(true);
        const userAssignment = getUserAssignment(userId, customerId);

        userAssignment
            .then(
                (data) => {
                    setUserAssignment(data);
                    const thematics = findThematics();

                    thematics
                        .then(
                            (data) => {
                                setThematics(data);
                                setShowModal(true)
                            }
                        )
                        .catch(
                            (error) => console.log(error)
                        );
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleDeleteIconClick = (userId, customerId) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure?')) {
            deleteUserAssignment(userId, customerId)
                .then(() => {
                    setSubmitCount(prevCount => prevCount + 1);
                    toast.success('Success!');
                })
                .catch(
                    (error) => {
                        toast.error(error.message);
                    }
                );
        }
    }

    return <Fragment>
        <Button title='Create assignment' handleClick={handleAddCustomerButtonClick}/>
        <Box>
            <ListEdit
                headers={listHeaders}
                items={userAssignmentList}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handleDeleteIconClick={handleDeleteIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
            {
                showModal && <UserAssignmentModal
                    isUpdate={isUpdate}
                    handleCancel={function () {
                        setShowModal(false);
                    }}
                    handleAddSubmit={handleAddSubmit}
                    handleEditSubmit={handleEditSubmit}
                    customers={customers}
                    thematics={thematics}
                    defaultModalCustomerValue={defaultModalCustomerValue}
                    userAssignment={userAssignment}
                    userId={props.userId}
                />
            }
        </Box>
    </Fragment>;
}

export default UserAssignmentList;