import {useCallback, useEffect, useState} from "react";
import {getCustomerLevel} from "../api/customerApiClient";

export const useCustomerLevel = (customerId, reloadTrigger) => {
    const [customerLevel, setCustomerLevel] = useState(null);

    const fetchCustomerLevel = useCallback(async () => {
        try {
            const data = await getCustomerLevel(customerId);
            setCustomerLevel(data);
        }
        catch (error) {
            console.log(error);
        }
    }, [customerId, reloadTrigger]);

    useEffect(() => {
        fetchCustomerLevel();
    }, [fetchCustomerLevel]);

    return {customerLevel : customerLevel};
};