import {useCallback, useEffect, useState} from "react";
import {getUser} from "../api/userApiClient";

export const useUser = (userId, reloadTrigger) => {
    const [user, setUser] = useState(null);

    const fetchUser = useCallback(async () => {
        try {
            const data = await getUser(userId);
            setUser(data);
        }
        catch (error) {
            console.log(error);
        }
    }, [userId, reloadTrigger]);

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    return {user : user};
};