import React, {Fragment, useState} from "react";
import ListEdit from "../UI/ListEdit";
import {useCustomerComments} from "../../hooks/useCustomerComments";
import {convertToDisplayDateTime} from "../../utility/utility";

const AllCustomerCommentList = (props) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const {customerComments, pagination} = useCustomerComments(null, page, pageSize);

    const allCustomerCommentList = customerComments.map((item) => {
        return {
            fullName  : item.customer.firstName + ' ' + item.customer.lastName,
            createdAt : convertToDisplayDateTime(item.createdAt),
            updatedAt : item.updatedAt && convertToDisplayDateTime(item.updatedAt),
            comment   : item.comment
        };
    });

    const listHeaders = [
        'Customer name',
        'Comment',
        'Created at',
        'Updated at'
    ];

    const columns = {
        functionParams : [],
        dataKeys       : [
            'fullName',
            'comment',
            'createdAt',
            'updatedAt'
        ],
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    return <Fragment>
        <ListEdit
            headers={listHeaders}
            items={allCustomerCommentList}
            columns={columns}
            pagination={pagination}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
        />
    </Fragment>
}

export default AllCustomerCommentList;