import ThematicFiguresList from "../../components/thematic/ThematicFiguresList";
import React from "react";
import {useParams} from 'react-router-dom';

const ThematicFiguresPage = () => {
    const {thematicId} = useParams();

    return <ThematicFiguresList thematicId={thematicId}/>
}

export default ThematicFiguresPage;