import React from "react";
import {useParams} from 'react-router-dom';
import CustomerLevelRequirementsList from "../../components/customer/CustomerLevelRequirementsList";

const CustomerLevelRequirementsPage = () => {
    const {customerId} = useParams();

    return <CustomerLevelRequirementsList customerId={customerId}/>
}

export default CustomerLevelRequirementsPage;