import React from "react";
import CustomerDetails from "../../components/customer/CustomerDetails";
import {useParams} from 'react-router-dom';

const CustomerDetailPage = () => {
    const {customerId} = useParams();

    return <CustomerDetails customerId={customerId}/>
}

export default CustomerDetailPage;