import React, {Fragment, useState} from "react";
import Button from "../UI/Button";
import ChangePasswordModal from "./ChangePasswordModal";

const ChangePasswordView = (props) => {
    const [showModal, setShowModal] = useState(false);

    const handleChangePasswordButtonClick = () => {
        setShowModal(true)
    }

    const handleChangeSubmit = () => {
        setShowModal(false)
    }

    return <Fragment>
        <Button color="white" bgColor="pink" title="Change password" handleClick={handleChangePasswordButtonClick}/>

        {
            showModal
            && <ChangePasswordModal
                handleChangeSubmit={handleChangeSubmit}
                handleCancel={function () {
                    setShowModal(false)
                }}
                userId={props.userId}
            />
        }
    </Fragment>;
}

export default ChangePasswordView;