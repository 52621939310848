import React from 'react';
import ReactDOM from 'react-dom/client';
import {RouterProvider} from "react-router-dom";
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import router from "./router";
import {ContextProvider} from "./contexts/ContextProvider";

//add .env
//install redux toolkit
//install router, create routes
//add authentication

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ContextProvider>
            <RouterProvider router={router}/>
        </ContextProvider>
    </React.StrictMode>
);
