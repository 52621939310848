import {useEffect, useState} from "react";
import {findUsersWithPagination} from "../api/userApiClient";

export const useUsers = (page, pageSize, reloadTrigger) => {
    const [users, setUsers] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const usersWithPagination = findUsersWithPagination(page, pageSize, abortController.signal);

        usersWithPagination
            .then(
                (data) => {
                    const users = data.users;
                    const pagination = data.pagination;

                    setUsers(users)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [page, pageSize, reloadTrigger]);

    return {users : users, pagination};
};