import {useEffect, useState} from "react";
import {getFigureProgressTracker} from "../api/figureProgressTrackerApiClient";

export const useFigureProgressTracker = (customerIds, thematicIds, reloadTrigger) => {
    const [figureProgressTracker, setFigureProgressTracker] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();

        if (customerIds?.length === 0)
        {
            return () => {
                abortController.abort();
            };
        }

        const figureProgressTracker = getFigureProgressTracker(customerIds, thematicIds ?? []);

        figureProgressTracker
            .then(
                (data) => {
                    setFigureProgressTracker(data)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerIds, thematicIds, reloadTrigger]);

    return {customersWithCustomerFigures : figureProgressTracker.customers ?? [], thematicsWithFigures: figureProgressTracker.thematics ?? []};
};