import {Fragment, useState} from 'react';
import React from "react";
import ListEdit from "../UI/ListEdit";
import Box from "../UI/Box";
import {useUsers} from "../../hooks/useUsers";
import {useNavigate} from "react-router-dom";
import Button from "../UI/Button";
import {findUserTypes, getUser} from "../../api/userApiClient";
import UserModal from "./UserModal";

const UserList = (props) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [submitCount, setSubmitCount] = useState(0);
    const {users, pagination} = useUsers(page, pageSize, submitCount);
    const [isUpdate, setIsUpdate] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userTypes, setUserTypes] = useState(null);
    const [user, setUser] = useState([]);
    const navigate = useNavigate();

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleShowIconClick = (userId) => {
        navigate('/users/' + userId);
    }

    const listHeaders = [
        'Id',
        'Name',
        'Email',
        'Type',
        'Actions'
    ];

    const columns = {
        functionParams : ['userId'],
        dataKeys       : [
            'userId',
            'name',
            'email',
            'type'
        ],
    }

    const handleAddUserButtonClick = (defaultModalUserId) => {
        setIsUpdate(false);
        const userTypes = findUserTypes();

        userTypes
            .then(
                (data) => {
                    const userTypes = data;

                    setUserTypes(userTypes)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );

    }

    const handleCreateSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    const handleEditSubmit = () => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    const handleEditIconClick = (userId) => {
        setIsUpdate(true);
        const user = getUser(userId);

        user
            .then(
                (data) => {
                    setUser(data);

                    const userTypes = findUserTypes();

                    userTypes.then(
                        (data) => {
                            const userTypes = data;

                            setUserTypes(userTypes)
                            setShowModal(true)
                        }
                    )
                    .catch(
                        (error) => console.log(error)
                    );
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    return <Fragment>
        <Button title='Create user' handleClick={handleAddUserButtonClick}/>
        <Box>
            <ListEdit
                headers={listHeaders}
                items={users}
                columns={columns}
                pagination={pagination}
                handleShowIconClick={handleShowIconClick}
                handleEditIconClick={handleEditIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {
            showModal
            && <UserModal
                isUpdate={isUpdate}
                handleEditSubmit={handleEditSubmit}
                handleCreateSubmit={handleCreateSubmit}
                handleCancel={function () {
                    setShowModal(false)
                }}
                user={user}
                userTypes={userTypes}
            />
        }
    </Fragment>;
}

export default UserList;