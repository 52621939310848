import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {useCustomer} from "../../hooks/useCustomer";
import CustomerModal from "./CustomerModal";


const CustomerView = (props) => {
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [customerSubmitCount, setCustomerSubmitCount] = useState(0);
    const {customer} = useCustomer(props.customerId, customerSubmitCount);

    const handleCustomerEditIconClick = () => {
        setShowCustomerModal(true)
    }

    const handleCustomerEditSubmit = () => {
        setShowCustomerModal(false)
        setCustomerSubmitCount(prevCount => prevCount + 1)
    }

    return <Fragment>
        <div className="flex justify-between mb-4">
            <Link to={'/customers'} className="text-blue-500 hover:underline">Back</Link>
            {
                customer
                && (
                    <div className="flex">
                        <h1 className="text-lg font-bold">
                            {customer.firstName} {customer.lastName}
                        </h1>
                        <FontAwesomeIcon onClick={handleCustomerEditIconClick} className="ml-5" icon={faEdit}/>
                    </div>
                )
            }
        </div>
        {customer && (
            <div>
                <div className="mb-2">
                    <span className="font-bold">Email:</span>{' '}
                    <span>{customer.email}</span>
                </div>
                <div className="mb-2">
                    <span className="font-bold">Phone:</span>{' '}
                    <span>{customer.phone}</span>
                </div>
                <div className="mb-2">
                    <span className="font-bold">Status:</span>{' '}
                    <span>{customer.status}</span>
                </div>
            </div>
        )}
        {
            showCustomerModal
            && <CustomerModal
                isUpdate={true}
                handleEditSubmit={handleCustomerEditSubmit}
                handleCreateSubmit={function () {
                }}
                handleCancel={function () {
                    setShowCustomerModal(false)
                }}
                customer={customer}
            />
        }
    </Fragment>;
}

export default CustomerView;