import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Pagination = ({
                        currentPage,
                        itemsPerPage,
                        totalItems,
                        onPageChange,
                        onPageSizeChange
                    }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pages = [...Array(totalPages).keys()].map((i) => i + 1);

    const handlePageChange = (pageNumber) => {
        if (pageNumber !== currentPage) {
            onPageChange(pageNumber);
        }
    };

    const handlePageSizeChange = (event) => {
        const pageSize = parseInt(event.target.value, 10);
        onPageSizeChange(pageSize);
    };

    const renderPageItem = (pageNumber, label) => {
        const active = pageNumber === currentPage;
        const classes = classNames(
            'inline-block py-2 px-3 mr-1 mb-2 rounded',
            {'bg-pink-500 text-white' : active},
            {'hover:bg-pink-500 hover:text-white' : !active},
            {'bg-gray-200' : !active},
        );
        return (
            <button
                key={pageNumber}
                className={classes}
                onClick={() => handlePageChange(pageNumber)}
                disabled={active}
            >
                {label ? label : pageNumber}
            </button>
        );
    };

    const renderPageSizeSelector = () => (
        <select
            className="inline-block py-2 px-3 mb-2 rounded border border-gray-400"
            value={itemsPerPage}
            onChange={handlePageSizeChange}
        >
            <option value={10}>10 per page</option>
            <option value={20}>20 per page</option>
            <option value={50}>50 per page</option>
            <option value={100}>100 per page</option>
        </select>
    );

    const renderPaginationInfo = () => {
        const from = (currentPage - 1) * itemsPerPage + 1;
        const to = Math.min(currentPage * itemsPerPage, totalItems);
        return (
            <p className="text-sm text-gray-700">
                Showing {from} to {to} of {totalItems} entries
            </p>
        );
    };

    const renderPaginationControls = () => (
        <div className="text-center">
            {currentPage > 3 && <span className="mx-2">...</span>}
            {pages
                .filter((page) => page >= currentPage - 2 && page <= currentPage + 2)
                .map((page) => renderPageItem(page))}
            {currentPage < totalPages - 2 && <span className="mx-2">...</span>}
        </div>
    );

    return (
        <div className="flex flex-wrap items-center justify-between">
            <div className="flex items-center mb-4">
                <span className="text-sm font-medium mr-2">Show:</span>
                {renderPageSizeSelector()}
            </div>
            <div className="mb-4">{renderPaginationInfo()}</div>
            <div className="mb-4">{renderPaginationControls()}</div>
        </div>
    );
};

Pagination.propTypes = {
    currentPage      : PropTypes.number.isRequired,
    itemsPerPage     : PropTypes.number.isRequired,
    totalItems       : PropTypes.number.isRequired,
    onPageChange     : PropTypes.func.isRequired,
    onPageSizeChange : PropTypes.func.isRequired,
};

export default Pagination;