import {API_URL, handleErrorResponse} from "../utility/utility";

export async function findUserAssignmentsWithPagination(userId, page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/user-assignments/${userId}?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch user assignments');

    return response.json();
}

export async function getUserAssignment(userId, customerId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/user-assignments/${userId}/${customerId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to get user assignment');

    return response.json();
}

export async function createUserAssignment(userId, customerId, thematicIds) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/user-assignments`, {
        method  : 'POST',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        body    : JSON.stringify({
            userId      : userId,
            customerId  : customerId,
            thematicIds : thematicIds
        })
    });

    await handleErrorResponse(response, 'Failed to create user assignment');
}

export async function updateUserAssignment(userId, customerId, thematicIds) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/user-assignments/${userId}/${customerId}`, {
        method  : 'PATCH',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        body    : JSON.stringify({
            thematicIds : thematicIds
        })
    });

    await handleErrorResponse(response, 'Failed to update user assignment');
}

export async function deleteUserAssignment(userId, customerId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/user-assignments/${userId}/${customerId}`, {
        method  : 'DELETE',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to delete user assignment');
}

export async function findNotAssignedCustomers(userId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/user-assignment-not-assigned-customers/${userId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch not assigned customers');

    return response.json();
}

export async function findThematics() {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/user-assignment-thematics`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch user assignment thematics');

    return response.json();

}
