import {API_URL, handleErrorResponse} from "../utility/utility";

export async function findExamsWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/exams?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch exams');

    return response.json();
}

export async function createExam(name) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/exams`, {
        method  : 'POST',
        body    : JSON.stringify({
            name : name
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create exam');
}

export async function updateExam(examId, name) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/exams/${examId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            name : name,
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update exam');
}

export async function getExam(examId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/exams/${examId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch exam');

    return await response.json();
}

export async function findExamRequirementsWithPagination(examId, page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/exam-requirements/${examId}?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch exam requirements');

    return response.json();
}

export async function findExamNotAssignedFiguresWithPagination(examId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', 1);
    params.append('limit', 1000);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/exam-requirements/${examId}/not-assigned?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch not assigned figures');

    return response.json();
}

export async function createExamRequirement(examId, figureId, minStatus) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/exam-requirements`, {
        method  : 'POST',
        body    : JSON.stringify({
            examId    : examId,
            figureId  : figureId,
            minStatus : minStatus
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to assign requirement to exam');
}

export async function updateExamRequirement(examId, figureId, minStatus) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/exam-requirements/${examId}/${figureId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            minStatus : minStatus
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update exam requirement status');
}

export async function getExamRequirement(examId, figureId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/exam-requirements/${examId}/${figureId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch exam requirement');

    return await response.json();
}

export async function findExamsWithRequirements(examIds, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();

    for (const id of examIds) {
        params.append('examIds[]', id);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/exam-with-requirements?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch exams with requirements');

    return await response.json();
}

export async function deleteExamRequirement(examId, figureId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/exam-requirements/${examId}/${figureId}`, {
        method  : 'DELETE',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to delete exam requirement');
}