import {useEffect, useState} from "react";
import {findLevelsWithPagination} from "../api/levelApiClient";

export const useLevels = (page, pageSize, reloadTrigger) => {
    const [levels, setLevels] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const levelsWithPagination = findLevelsWithPagination(page, pageSize, abortController.signal);

        levelsWithPagination
            .then(
                (data) => {
                    const levels = data.levels;
                    const pagination = data.pagination;

                    setLevels(levels)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [page, pageSize, reloadTrigger]);

    return {levels : levels, pagination};
};