import ListEdit from "../UI/ListEdit";
import {useThematicFigures} from "../../hooks/useThematicFigures";
import {Link} from "react-router-dom";
import React, {Fragment, useRef, useState} from "react";
import {useThematic} from "../../hooks/useThematic";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import {createThematicFigure, deleteThematicFigure, findThematicNotAssignedFiguresWithPagination, getThematicFigure, updateThematicFigure} from "../../api/thematicApiClient";
import Box from "../UI/Box";
import {toast} from "react-toastify";

const ThematicFiguresList = (props) => {
    const {thematic} = useThematic(props.thematicId);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [figures, setFigures] = useState(null);
    const [thematicFigure, setThematicFigure] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const {thematicFigures, pagination} = useThematicFigures(props.thematicId, page, pageSize, submitCount);
    const selectRef = useRef(null);
    const orderRef = useRef(null);

    const thematicFigureList = thematicFigures.map((item) => {
        const categoryNames = item.figure.categories.map(function (category, index) {
            return category.name;
        }).join(', ');

        return {thematicId : item.thematicId, figureId : item.figure.figureId, name : item.figure.name, categories : categoryNames, order : item.order};
    });

    const listHeaders = [
        'Name',
        'Categories',
        'Order'
    ];

    const columns = {
        functionParams : [
            'thematicId', 'figureId'
        ],
        dataKeys       : [
            'name',
            'categories',
            'order'
        ]
    }

    const handleAddFigureButtonClick = () => {
        const figuresWithPagination = findThematicNotAssignedFiguresWithPagination(props.thematicId);

        figuresWithPagination
            .then(
                (data) => {
                    const figures = data.figures;

                    setFigures(figures)
                    setIsUpdate(false)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );

    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleAddSubmit = (event) => {
        event.preventDefault();
        const figureId = selectRef.current.value;
        const order = orderRef.current.value;

        createThematicFigure(props.thematicId, figureId, order)
            .then(() => {
                setShowModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditIconClick = (thematicId, figureId) => {
        setIsUpdate(true);
        const thematicFigure = getThematicFigure(thematicId, figureId);

        thematicFigure
            .then(
                (data) => {
                    setThematicFigure(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleDeleteIconClick = (thematicId, figureId) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure?')) {
            deleteThematicFigure(thematicId, figureId)
                .then(() => {
                    setSubmitCount(prevCount => prevCount + 1);
                    toast.success('Success!');
                })
                .catch(
                    (error) => {
                        toast.error(error.message);
                    }
                );
        }
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const order = orderRef.current.value;

        updateThematicFigure(props.thematicId, thematicFigure.figure.figureId, order)
            .then(() => {
                setShowModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    return (
        <Fragment>
            <Link to={'/thematics'}>Back</Link>
            <Box>
                {thematic && <h1>Thematic: {thematic.name}</h1>}
                <Button title='Add figure' handleClick={handleAddFigureButtonClick}/>
                <ListEdit
                    headers={listHeaders}
                    items={thematicFigureList}
                    columns={columns}
                    pagination={pagination}
                    handleEditIconClick={handleEditIconClick}
                    handleDeleteIconClick={handleDeleteIconClick}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                />
            </Box>
            {showModal && (
                <Modal>
                    <form onSubmit={isUpdate ? handleEditSubmit : handleAddSubmit}>
                        <ModalForm
                            title={isUpdate ? 'Update figure order' : 'Add figure'}
                            saveButtonTitle={isUpdate ? 'Update' : 'Add'}
                            handleCancelButtonClick={() => setShowModal(false)}
                        >
                            <ModalFormInputRow title='Figure'>
                                {
                                    !isUpdate && figures && <select ref={selectRef} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                                        {
                                            figures.map(
                                                (option) => (
                                                    <option key={option.figureId} value={option.figureId}>
                                                        {option.name} ({option.categories.map((category) => category.name).join(', ')}) {option.grip !== null ? ` ${option.grip.name}` : null}
                                                    </option>
                                                )
                                            )
                                        }
                                    </select>
                                }
                                {
                                    isUpdate && thematicFigure && <input
                                        type="text"
                                        id="figureName"
                                        name="figureName"
                                        defaultValue={thematicFigure.figure.name}
                                        readOnly={true}
                                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                    />
                                }
                            </ModalFormInputRow>
                            <ModalFormInputRow title='Order'>
                                <input
                                    ref={orderRef}
                                    type="text"
                                    id="order"
                                    name="order"
                                    defaultValue={isUpdate ? thematicFigure.order : 1}
                                    placeholder="Enter order"
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>
                        </ModalForm>
                    </form>
                </Modal>
            )}
        </Fragment>

    );
};

export default ThematicFiguresList;