import React, {Fragment, useState} from 'react';
import {Outlet, Navigate} from 'react-router-dom';
import Sidebar from './Sidebar';
import {useStateContext} from '../../contexts/ContextProvider';
import {ToastContainer} from 'react-toastify';
import {useInitAuthenticatedUser} from "../../hooks/useInitAuthenticatedUser";
import TeacherSidebar from "./TeacherSidebar";
import {USER_TYPE_SUPER_ADMIN, USER_TYPE_TEACHER} from "../../utility/utility";

const DashboardLayout = () => {
    useInitAuthenticatedUser();
    const {user, token} = useStateContext();
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen((prevState) => {
            return !prevState
        });
    };

    if (!token) {
        return <Navigate to="/login"/>;
    }

    return (
        <Fragment>
            <div className="flex h-screen bg-gray-200">
                {/* Sidebar */}
                {(isSidebarOpen || window.innerWidth >= 1024) && (
                    <>
                        {user.type === USER_TYPE_SUPER_ADMIN && (
                            <Sidebar/>
                        )}

                        {user.type === USER_TYPE_TEACHER && (
                            <TeacherSidebar/>
                        )}
                    </>
                )}
                <div className="flex flex-col flex-1 overflow-hidden">
                    <header className="bg-white shadow">
                        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center">
                            {/* Hamburger menu */}
                            <div
                                className="lg:hidden"
                                onClick={toggleSidebar}
                                style={{cursor : 'pointer'}}
                            >
                                <svg
                                    className="h-6 w-6 m-2"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    {isSidebarOpen ? (
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    ) : (
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    )}
                                </svg>
                            </div>

                            {/* Dashboard title */}
                            <h1 className="text-lg font-bold leading-tight text-gray-900 ml-2">
                                Dashboard
                            </h1>
                        </div>
                    </header>
                    <main className="flex-1 overflow-y-auto p-4">
                        <Outlet/>
                    </main>
                </div>
            </div>
            <ToastContainer position="top-right" autoClose={1500}/>
        </Fragment>
    );
};

export default DashboardLayout;
