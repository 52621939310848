import React from "react";

const ModalFormInputsContainer = (props) => {
    return (
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                {props.title}
            </h3>
            {props.children}
        </div>
    );
}

export default ModalFormInputsContainer;