import {useCallback, useEffect, useState} from "react";
import {getExam} from "../api/examApiClient";

export const useExam = (examId) => {
    const [exam, setExam] = useState(null);

    const fetchExam = useCallback(async () => {
        try {
            const data = await getExam(examId);
            setExam(data);
        }
        catch (error) {
            console.log(error);
        }
    }, [examId]);

    useEffect(() => {
        fetchExam();
    }, [fetchExam]);

    return {exam : exam};
};