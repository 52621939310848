import React from "react";
import Button from "./Button";

const ModalFormButtons = (props) => {
    return (
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <Button isSubmit={true} title={props.saveTitle} handleClick={props.handleCancelClick}/>
            <Button color="black" bgColor="white" title="Cancel" handleClick={props.handleCancelClick}/>
        </div>
    );
}

export default ModalFormButtons;