import {API_URL, handleErrorResponse} from "../utility/utility";

export async function getFigureProgressTracker(customerIds, thematicIds, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();

    for (const id of customerIds) {
        params.append('customerIds[]', id);
    }

    for (const id of thematicIds) {
        params.append('thematicIds[]', id);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/figure-progress-tracker?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch figure progress tracker');

    return await response.json();
}