import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faCircleCheck, faCircleHalfStroke, faCircleXmark, faWarning} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const CUSTOMER_FIGURE_STATUS_NOTHING = 'nothing';
export const CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED = 'not_achieved';
export const CUSTOMER_FIGURE_STATUS_IN_PROGRESS = 'in_progress';
export const CUSTOMER_FIGURE_STATUS_ACHIEVED = 'achieved';
export const CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED = 'consistently_achieved';
export const DEFAULT_EXAM_REQUIREMENT_MIN_STATUS = CUSTOMER_FIGURE_STATUS_ACHIEVED;
export const CUSTOMER_STATUS_ACTIVE = 'active';
export const CUSTOMER_STATUS_INACTIVE = 'inactive';
export const USER_TYPE_SUPER_ADMIN = 'super_admin';
export const USER_TYPE_TEACHER = 'teacher';

export const getCustomerFigureDisplayStatus = (status) => {
    switch (status) {
        case CUSTOMER_FIGURE_STATUS_ACHIEVED:
            return <FontAwesomeIcon icon={faCircleCheck} style={{color : 'green'}}/>;
        case CUSTOMER_FIGURE_STATUS_IN_PROGRESS:
            return <FontAwesomeIcon icon={faCircleHalfStroke} style={{color : 'orange'}}/>;
        case CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED:
            return <FontAwesomeIcon icon={faCircleXmark} style={{color : 'red'}}/>;
        case CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED:
            return <FontAwesomeIcon icon={faCircleCheck} style={{color : 'blue'}}/>;
        default:
            return <FontAwesomeIcon icon={faCircle} style={{color : 'lightgrey'}}/>;
    }
}

export const getNeedToLevelIcon = () => {
    return <FontAwesomeIcon icon={faWarning} style={{color : "#ff0000",}}/>;
}

export const readyToNextLevelIcon = <FontAwesomeIcon icon={faCircleCheck} style={{color : 'green'}}/>;
export const notReadyToNextLevelIcon = <FontAwesomeIcon icon={faCircleXmark} style={{color : 'red'}}/>;

export const getCustomerFigureStatuses = () => {
    return [
        {id : CUSTOMER_FIGURE_STATUS_NOTHING, name : 'Nothing'},
        {id : CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED, name : 'No'},
        {id : CUSTOMER_FIGURE_STATUS_IN_PROGRESS, name : 'Pending'},
        {id : CUSTOMER_FIGURE_STATUS_ACHIEVED, name : 'Yes'},
        {id : CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED, name : 'Excellent'}
    ]
}

export const getCustomerFigureStatusNameById = (id) => {
    const customerFigureStatuses = getCustomerFigureStatuses();
    const status = customerFigureStatuses.find(status => status.id === id);

    return status ? status.name : null;
};

export const handleErrorResponse = async (response, message) => {
    if (!response.ok) {
        if (response.status === 401) {
            localStorage.removeItem('ACCESS_TOKEN')
        }

        const responseJson = await response.json();

        throw {message : responseJson.message, code : responseJson.code, status : response.status};
    }
}

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

export const API_URL = process.env.REACT_APP_API_URL;

export const convertToDisplayDateTime = (dateTime) => {
    const dt = new Date(dateTime);

    return dt.toLocaleString('en-US', {
        year   : 'numeric',
        month  : 'long',
        day    : 'numeric',
        hour   : 'numeric',
        minute : 'numeric',
        hour12 : false,
    });
}

export const convertToDisplayDate = (date) => {
    const d = new Date(date);

    return d.toLocaleString('en-US', {
        year  : 'numeric',
        month : 'long',
        day   : 'numeric'
    });
}

export const createNowDate = () => {
    return new Date().toISOString().substr(0, 10);
}

export const getCustomerStatuses = () => {
    return [
        CUSTOMER_STATUS_ACTIVE,
        CUSTOMER_STATUS_INACTIVE
    ];
}