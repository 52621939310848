import {useEffect, useState} from "react";
import {findCustomerFigureCommentHistoriesWithPagination} from "../api/customerApiClient";

export const useCustomerFigureCommentHistories = (customerId, page, pageSize, reloadTrigger) => {
    const [customerFigureCommentHistories, setCustomerFigureCommentHistories] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const customerFigureCommentHistoriesWithPagination = findCustomerFigureCommentHistoriesWithPagination(page, pageSize, customerId, abortController.signal);

        customerFigureCommentHistoriesWithPagination
            .then(
                (data) => {
                    const customerFigureCommentHistories = data.customerFigureCommentHistories;
                    const pagination = data.pagination;

                    setCustomerFigureCommentHistories(customerFigureCommentHistories)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerId, page, pageSize, reloadTrigger]);

    return {customerFigureCommentHistories : customerFigureCommentHistories, pagination};
};