import React, {useRef} from "react";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import {createCustomerLevel, updateCustomerLevel} from "../../api/customerApiClient";
import {toast} from "react-toastify";

const CustomerLevelModal = (props) => {
    const isUpdate = props.isUpdate;
    const handleCancel = props.handleCancel;
    const defaultModalCustomerLevelValue = props.defaultModalCustomerLevelValue;
    const levelRef = useRef(null);
    const happenedAtRef = useRef(null);
    const customerId = props.customerId;
    const customerLevel = props.customerLevel;

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        const levelId = levelRef.current.value;
        const happenedAt = happenedAtRef.current.value;

        createCustomerLevel(customerId, levelId, happenedAt)
            .then(() => {
                props.handleCreateSubmit();
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const levelId = levelRef.current.value;
        const happenedAt = happenedAtRef.current.value;

        updateCustomerLevel(customerId, levelId, happenedAt)
            .then(() => {
                props.handleEditSubmit(levelId, happenedAt);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    return <Modal>
        <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
            <ModalForm
                title={isUpdate ? 'Update customer level' : 'Create customer level'}
                saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                handleCancelButtonClick={handleCancel}
            >
                <ModalFormInputRow title='Level'>
                    {
                        props.levels && <select ref={levelRef} defaultValue={defaultModalCustomerLevelValue} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                            {
                                props.levels.map(
                                    (option) => (
                                        <option key={option.levelId} value={option.levelId}>{option.name}</option>
                                    )
                                )
                            }
                        </select>
                    }
                </ModalFormInputRow>
                <ModalFormInputRow title='Happened at'>
                    <input
                        ref={happenedAtRef}
                        type="date"
                        id="happenedAt"
                        name="happenedAt"
                        defaultValue={new Date().toISOString().substr(0, 10)}
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
            </ModalForm>
        </form>
    </Modal>
}

export default CustomerLevelModal;