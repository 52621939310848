import {API_URL, handleErrorResponse} from "../utility/utility";

export async function createLevel(name, order) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/levels`, {
        method  : 'POST',
        body    : JSON.stringify({
            name : name,
            order : order
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create level');
}

export async function updateLevel(levelId, name, order) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/levels/${levelId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            name : name,
            order : order,
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update level');
}

export async function getLevel(levelId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/levels/${levelId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch level');

    return await response.json();
}

export async function findLevelsWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/levels?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch levels');

    return response.json();
}

export async function findLevelRequirementsWithPagination(levelId, page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/levels/${levelId}/requirements/?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch level requirements');

    return response.json();
}

export async function findLevelNotAssignedFiguresWithPagination(levelId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', 1);
    params.append('limit', 1000);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/levels/${levelId}/requirements-not-assigned?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch not assigned figures');

    return response.json();
}

export async function createLevelRequirement(levelId, figureId, minStatus) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/levels/${levelId}/requirements`, {
        method  : 'POST',
        body    : JSON.stringify({
            figureId  : figureId,
            minStatus : minStatus
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to assign requirement to exam');
}

export async function updateLevelRequirement(levelId, figureId, minStatus) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/levels/${levelId}/requirements/${figureId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            minStatus : minStatus
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update level requirement status');
}

export async function getLevelRequirement(levelId, figureId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/levels/${levelId}/requirements/${figureId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch level requirement');

    return await response.json();
}

export async function deleteLevelRequirement(levelId, figureId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/levels/${levelId}/requirements/${figureId}`, {
        method  : 'DELETE',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to delete level requirement');
}