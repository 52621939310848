import React, {Fragment} from "react";
import Dashboard from "../../components/dashboard/Dashboard";
import {useStateContext} from "../../contexts/ContextProvider";
import {Navigate} from "react-router-dom";

const DashboardPage = () => {
    const {user} = useStateContext();

    if (user && user.type === 'teacher') {
        return <Navigate to={'/customer-thematics'} />;
    }

    return <Fragment>
        <Dashboard/>
    </Fragment>
}

export default DashboardPage;