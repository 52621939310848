import {useCallback, useEffect, useState} from "react";
import {getThematic} from "../api/thematicApiClient";

export const useThematic = (thematicId) => {
    const [thematic, setThematic] = useState(null);

    const fetchThematic = useCallback(async () => {
        try {
            const data = await getThematic(thematicId);
            setThematic(data);
        }
        catch (error) {
            console.log(error);
        }
    }, [thematicId]);

    useEffect(() => {
        fetchThematic();
    }, [fetchThematic]);

    return {thematic : thematic};
};