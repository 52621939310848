import ListEdit from "../UI/ListEdit";
import {Link} from "react-router-dom";
import React, {Fragment, useState} from "react";
import Box from "../UI/Box";
import {getCustomerFigureStatusNameById} from "../../utility/utility";
import {useCustomerLevelRequirements} from "../../hooks/useCustomerLevelRequirements";

const CustomerLevelRequirementsList = (props) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const {customer, nextLevel, customerLevelRequirements, pagination} = useCustomerLevelRequirements(props.customerId, page, pageSize);

    const levelRequirementList = customerLevelRequirements.map((item) => {
        const figureName = item.levelRequirement.figure.name;
        const figureCategoryNames = item.levelRequirement.figure.categories.map(function (category, index) {
            return category.name;
        }).join(', ');
        const customerFigureStatus = item.customerFigure ? getCustomerFigureStatusNameById(item.customerFigure.status) : null;
        const customerRequirementStatus = getCustomerFigureStatusNameById(item.levelRequirement.minStatus);

        return {
            figure                    : `${figureName} (${figureCategoryNames})`,
            customerFigureStatus      : customerFigureStatus,
            customerRequirementStatus : customerRequirementStatus
        };
    });

    const listHeaders = [
        'Figure',
        'Actual status',
        'Min. required status'
    ];

    const columns = {
        functionParams : [],
        dataKeys       : [
            'figure',
            'customerFigureStatus',
            'customerRequirementStatus'
        ]
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    return (
        <Fragment>
            <Link to={'/customer-level-requirement-summaries'}>Back</Link>
            <Box>
                {customer && <h1>{customer.firstName} {customer.lastName}</h1>}
                {nextLevel && <h3>Missing figure requirements to {nextLevel?.name}</h3>}
                <ListEdit
                    headers={listHeaders}
                    items={levelRequirementList}
                    columns={columns}
                    pagination={pagination}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                />
            </Box>
        </Fragment>

    );
};

export default CustomerLevelRequirementsList;