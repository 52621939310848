import {useEffect, useState} from "react";
import {findExamRequirementsWithPagination} from "../api/examApiClient";

export const useExamRequirements = (examId, page, pageSize, reloadTrigger) => {
    const [examRequirements, setExamRequirements] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const examRequirementsWithPagination = findExamRequirementsWithPagination(examId, page, pageSize, abortController.signal);

        examRequirementsWithPagination
            .then(
                (data) => {
                    const examRequirements = data.examRequirements;
                    const pagination = data.pagination;

                    setExamRequirements(examRequirements)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [examId, page, pageSize, reloadTrigger]);

    return {examRequirements : examRequirements, pagination};
};