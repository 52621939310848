import React, {Fragment, useState} from "react";
import {useCustomerLevel} from "../../hooks/useCustomerLevel";
import CustomerLevelModal from "./CustomerLevelModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import Button from "../UI/Button";
import {useLevels} from "../../hooks/useLevels";

const CustomerLevelView = (props) => {
    const [showCustomerLevelModal, setShowCustomerLevelModal] = useState(false);
    const [customerLevelSubmitCount, setCustomerLevelSubmitCount] = useState(0);
    const {customerLevel} = useCustomerLevel(props.customerId, customerLevelSubmitCount);
    const [isUpdate, setIsUpdate] = useState(false);
    const [defaultModalCustomerLevelValue, setDefaultModalCustomerLevelValue] = useState(null);
    const {levels} = useLevels(1, 100, 1);

    const handleCustomerLevelEditIconClick = () => {
        setDefaultModalCustomerLevelValue(customerLevel.level.levelId)
        setIsUpdate(true)
        setShowCustomerLevelModal(true)
    }

    const handleCustomerLevelCreateIconClick = () => {
        setDefaultModalCustomerLevelValue(null)
        setIsUpdate(false)
        setShowCustomerLevelModal(true)
    }

    const handleCustomerLevelEditSubmit = () => {
        setShowCustomerLevelModal(false)
        setCustomerLevelSubmitCount(prevCount => prevCount + 1)
    }

    return <Fragment>
        {customerLevel && (
            <div className="flex justify-between mb-4">
                <div>
                    {customerLevel && <div className="mb-2">
                        <span className="font-bold">Name:</span>{' '}
                        <span>{customerLevel.level.name}</span>
                    </div>}
                    {customerLevel && <div className="mb-2">
                        <span className="font-bold">Happened at:</span>{' '}
                        <span>{customerLevel.happenedAt}</span>
                    </div>}
                    {!customerLevel && <div>nincs</div>}
                </div>
                {
                    customerLevel
                    && (
                        <div className="flex">
                            <FontAwesomeIcon onClick={handleCustomerLevelEditIconClick} className="ml-5" icon={faEdit}/>
                        </div>
                    )
                }
            </div>
        )}
        {
            !customerLevel && (<Button color="white" bgColor="pink" title="Add customer level" handleClick={handleCustomerLevelCreateIconClick}/>)
        }
        {
            showCustomerLevelModal
            && <CustomerLevelModal
                isUpdate={isUpdate}
                handleEditSubmit={handleCustomerLevelEditSubmit}
                handleCreateSubmit={handleCustomerLevelEditSubmit}
                handleCancel={function () {
                    setShowCustomerLevelModal(false)
                }}
                customerId={props.customerId}
                customerLevel={customerLevel}
                defaultModalCustomerLevelValue={defaultModalCustomerLevelValue}
                levels={levels}
            />
        }
    </Fragment>;
}

export default CustomerLevelView;