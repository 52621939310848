import React, {Fragment} from "react";

const Box = (props) => {
    return <Fragment>
        <div className="bg-gray-100 p-4 rounded-md my-3">
            {
                props.title && <h1 className="text-lg font-bold leading-tight text-gray-900 pb-5">
                    {props.title}
                </h1>
            }
            {props.children}
        </div>
    </Fragment>
}

export default Box;