import {useCallback, useEffect, useState} from "react";
import {getCustomer} from "../api/customerApiClient";

export const useCustomer = (customerId, reloadTrigger) => {
    const [customer, setCustomer] = useState(null);

    const fetchCustomer = useCallback(async () => {
        try {
            const data = await getCustomer(customerId);
            setCustomer(data);
        }
        catch (error) {
            console.log(error);
        }
    }, [customerId, reloadTrigger]);

    useEffect(() => {
        fetchCustomer();
    }, [fetchCustomer]);

    return {customer : customer};
};