import {API_URL, handleErrorResponse} from "../utility/utility";

export async function findFiguresWithPagination(page, limit, name, figureCategoryId, figureGripId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    if (name) {
        params.append('name', name);
    }

    if (figureCategoryId) {
        params.append('figureCategoryIds[]', figureCategoryId);
    }

    if (figureGripId) {
        params.append('figureGripIds[]', figureGripId);
    }

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/figures?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    handleErrorResponse(response, 'Failed to fetch figures', 500);

    return response.json();
}

export async function createFigure(name, figureCategoryIds, gripId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/figures`, {
        method  : 'POST',
        body    : JSON.stringify({
            name              : name,
            figureCategoryIds : figureCategoryIds,
            gripId            : gripId
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create figure');
}

export async function updateFigure(figureId, name, figureCategoryIds, gripId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/figures/${figureId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            name              : name,
            figureCategoryIds : figureCategoryIds,
            gripId            : gripId
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update figure');
}

export async function findFigureCategoriesWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/figure-categories?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch figure categories');

    return response.json();
}

export async function findGripsWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);

    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/grips?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch grips');

    return response.json();
}

export async function getFigure(figureId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/figures/${figureId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch figure');

    return await response.json();
}

export async function createFigureCategory(name) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/figure-categories`, {
        method  : 'POST',
        body    : JSON.stringify({
            name : name
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create figure category');
}

export async function createGrip(name) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/grips`, {
        method  : 'POST',
        body    : JSON.stringify({
            name : name
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create grip');
}

export async function updateFigureCategory(figureCategoryId, name) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/figure-categories/${figureCategoryId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            name : name,
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update figure category');
}

export async function updateGrip(gripId, name) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/grips/${gripId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            name : name,
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update grip');
}

export async function getFigureCategory(figureCategoryId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/figure-categories/${figureCategoryId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch figure category');

    return await response.json();
}

export async function getGrip(gripId) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/grips/${gripId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to fetch grip');

    return await response.json();
}