import {useCallback, useEffect, useState} from "react";
import {findCustomerLevelRequirementSummariesWithPagination} from "../api/customerApiClient";

export const useCustomerLevelRequirementSummaries = (page, pageSize) => {
    const [customerLevelRequirementSummaries, setCustomerLevelRequirementSummaries] = useState([]);
    const [pagination, setPagination] = useState(null);

    const fetchCustomerLevelRequirementSummaries = useCallback(async () => {
        try {
            const data = await findCustomerLevelRequirementSummariesWithPagination(page, pageSize);
            setCustomerLevelRequirementSummaries(data.customerLevelRequirementSummaries)
            setPagination(data.pagination)
        }
        catch (error) {
            console.log(error);
        }
    }, [page, pageSize]);

    useEffect(() => {
        fetchCustomerLevelRequirementSummaries();
    }, [fetchCustomerLevelRequirementSummaries]);

    return {customerLevelRequirementSummaries, pagination};
};