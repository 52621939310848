import React, {Fragment, useEffect, useRef, useState} from "react";
import Button from "../UI/Button";
import {convertToDisplayDate, getCustomerFigureDisplayStatus, CUSTOMER_FIGURE_STATUS_ACHIEVED, CUSTOMER_FIGURE_STATUS_IN_PROGRESS, CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED, CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED} from "../../utility/utility";
import ListEdit from "../UI/ListEdit";
import {deleteCustomerFigure, findCustomerNotAssignedFiguresWithPagination, getCustomerFigure} from "../../api/customerApiClient";
import {useCustomerFigures} from "../../hooks/useCustomerFigures";
import CustomerFigureModal from "./CustomerFigureModal";

const CustomerFigureList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [figures, setFigures] = useState(null);
    const [defaultModalFigureValue, setDefaultModalFigureValue] = useState(null);
    const [defaultModalStatusValue, setDefaultModalStatusValue] = useState(null);
    const [customerFigure, setCustomerFigure] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const nameFilterRef = useRef(null);
    const selectFilterRef = useRef(null);
    const [filterName, setFilterName] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const {customerFigures, pagination} = useCustomerFigures(props.customerId, page, pageSize, filterName, filterStatus, submitCount + props.submitCount);

    useEffect(() => {
        if (props.triggerHandleStatusIconClickData ?? false) {
            handleStatusButtonIconClick(
                props.triggerHandleStatusIconClickData.customerId,
                props.triggerHandleStatusIconClickData.figureId,
                props.triggerHandleStatusIconClickData.status
            );
        }
    }, [props.triggerHandleStatusIconClickData]);

    const customerFigureList = customerFigures.map((item) => {
        const categoryNames = item.figure.categories.map(function (category, index) {
            return category.name;
        }).join(', ');

        return {
            customerId : props.customerId,
            figureId   : item.figure.figureId,
            name       : item.figure.name,
            categories : categoryNames,
            status     : getCustomerFigureDisplayStatus(item.status),
            happenedAt : convertToDisplayDate(item.happenedAt),
            comment    : item.comment
        };
    });

    const listHeaders = [
        'Figure',
        'Categories',
        'Status',
        'Comment',
        'Happened at',
        'Actions'
    ];

    const columns = {
        functionParams : [
            'customerId', 'figureId'
        ],
        dataKeys       : [
            'name',
            'categories',
            'status',
            'comment',
            'happenedAt'
        ],
    }

    const handleAddFigureButtonClick = (defaultModalFigureId) => {
        setIsUpdate(false);
        const figuresWithPagination = findCustomerNotAssignedFiguresWithPagination(props.customerId);

        figuresWithPagination
            .then(
                (data) => {
                    const figures = data.figures;

                    setFigures(figures)
                    setDefaultModalFigureValue(defaultModalFigureId ?? null)
                    setDefaultModalStatusValue(null)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );

    }

    const handleNameFilterChange = () => {
        setPage(1);
        setFilterName(nameFilterRef.current.value)
    }

    const handleStatusFilterChange = () => {
        setPage(1);
        setFilterStatus(selectFilterRef.current.value)
    }

    const handleStatusButtonIconClick = (customerId, figureId, status) => {
        setIsUpdate(true);
        const customerFigure = getCustomerFigure(customerId, figureId);

        customerFigure
            .then(
                (data) => {
                    setCustomerFigure(data)
                    setDefaultModalStatusValue(status ?? data.status)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => {
                    if (error.status === 400 && error.code === 101) {
                        setDefaultModalFigureValue(figureId)
                        setDefaultModalStatusValue(status ?? CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED)
                        handleAddFigureButtonClick(figureId)
                    }
                    else {
                        console.log(error)
                    }
                }
            );
    }

    const handleDeleteIconClick = (customerId, figureId) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure?')) {
            deleteCustomerFigure(customerId, figureId);
            setSubmitCount(prevCount => prevCount + 1);
        }
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleAddSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);

        if (props?.triggerHandleStatusIconClickData?.callback) {
            props.triggerHandleStatusIconClickData.callback();
        }
    }

    const handleEditSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);

        if (props?.triggerHandleStatusIconClickData?.callback) {
            props.triggerHandleStatusIconClickData.callback();
        }
    }

    return <Fragment>
        <Button title='Add figure' handleClick={handleAddFigureButtonClick}/>
        <div className="flex my-4">
            <input ref={nameFilterRef} type="text" placeholder="Filter by figure" className="border p-2 mr-2" onChange={handleNameFilterChange}/>

            <select ref={selectFilterRef} onChange={handleStatusFilterChange} className="border p-2 mr-2">
                <option key={0} value="">Filter by status</option>
                <option key={1} value={CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED}>Not Achieved</option>
                <option key={2} value={CUSTOMER_FIGURE_STATUS_IN_PROGRESS}>In progress</option>
                <option key={3} value={CUSTOMER_FIGURE_STATUS_ACHIEVED}>Achieved</option>
                <option key={4} value={CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED}>Consistently achieved</option>
            </select>
        </div>
        <ListEdit
            headers={listHeaders}
            items={customerFigureList}
            columns={columns}
            pagination={pagination}
            handleEditIconClick={handleStatusButtonIconClick}
            handleDeleteIconClick={handleDeleteIconClick}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
        />
        {
            showModal && <CustomerFigureModal
                isUpdate={isUpdate}
                handleAddSubmit={handleAddSubmit}
                handleEditSubmit={handleEditSubmit}
                handleCancel={function () {
                    setShowModal(false);
                }}
                figures={figures}
                defaultModalFigureValue={defaultModalFigureValue}
                defaultModalStatusValue={defaultModalStatusValue}
                customerFigure={customerFigure}
                customerId={props.customerId}
            />
        }
    </Fragment>
}

export default CustomerFigureList;