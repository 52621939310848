import {useEffect, useState} from "react";
import {findThematicsWithPagination} from "../api/thematicApiClient";

export const useThematics = (page, pageSize, reloadTrigger) => {
    const [thematics, setThematics] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const thematicsWithPagination = findThematicsWithPagination(page, pageSize, abortController.signal);

        thematicsWithPagination
            .then(
                (data) => {
                    const thematics = data.thematics;
                    const pagination = data.pagination;

                    setThematics(thematics)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => {
                    if (error.name === "AbortError") {
                        console.log("Aborted");
                    }
                    else {
                        console.log(error);
                    }
                }
            );

        return () => {
            abortController.abort();
        };
    }, [page, pageSize, reloadTrigger]);

    return {thematics, pagination};
};