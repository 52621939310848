import React, {Fragment, useState} from "react";
import ListEdit from "../UI/ListEdit";
import {convertToDisplayDateTime} from "../../utility/utility";
import {useCustomerFigureCommentHistories} from "../../hooks/useCustomerFigureCommentHistories";

const CustomerFigureCommentHistoryList = (props) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const {customerFigureCommentHistories, pagination} = useCustomerFigureCommentHistories(props.customerId, page, pageSize);

    const customerFigureCommentHistoryList = customerFigureCommentHistories.map((item) => {
        const categoryNames = item.figure.categories.map(function (category, index) {
            return category.name;
        }).join(', ');

        return {
            customerFigureCommentHistoryId : item.customerFigureCommentHistoryId,
            figureName                     : item.figure.name,
            categories                     : categoryNames,
            comment                        : item.comment,
            createdAt                      : convertToDisplayDateTime(item.createdAt),
            updatedAt                      : item.updatedAt && convertToDisplayDateTime(item.updatedAt)
        };
    });

    const listHeaders = [
        'Figure',
        'Comment',
        'Created at',
        'Updated at'
    ];

    const columns = {
        functionParams : [
            'customerFigureCommentHistoryId'
        ],
        dataKeys       : [
            'figureName',
            'comment',
            'createdAt',
            'updatedAt'
        ],
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    return <Fragment>
        <ListEdit
            headers={listHeaders}
            items={customerFigureCommentHistoryList}
            columns={columns}
            pagination={pagination}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
        />
    </Fragment>
}

export default CustomerFigureCommentHistoryList;