import React, {Fragment, useState} from 'react';
import {useCustomer} from "../../hooks/useCustomer";
import Box from "../UI/Box";
import {useThematics} from "../../hooks/useThematics";
import CustomerFigureList from "./CustomerFigureList";
import CustomerView from "./CustomerView";
import CustomerCommentList from "./CustomerCommentList";
import CustomerFigureCommentHistoryList from "./CustomerFigureCommentHistoryList";
import CustomerLevelView from "./CustomerLevelView";
import FigureProgressTrackerTable from "../figureProgressTracker/FigureProgressTrackerTable";

const CustomerDetails = (props) => {
    const [triggerHandleStatusIconClickData, setTriggerHandleStatusIconClickData] = useState(null);
    const [submitCountTable, setSubmitCountTable] = useState(0);
    const [submitCountList, setSubmitCountList] = useState(0);
    const {customer} = useCustomer(props.customerId);
    const {thematics} = useThematics(1, 100, 0);
    const [thematicFilters, setThematicFilters] = useState([]);
    const [customerIds, setCustomerIds] = useState([props.customerId]);

    const handleStatusButtonIconClick = (customerId, figureId, status) => {
        setTriggerHandleStatusIconClickData({
            customerId : customerId, figureId : figureId, status : status, callback : () => {
                setSubmitCountTable(prevCount => prevCount + 1)
            }
        });
    }

    const handleStatusButtonClick = () => {
        setSubmitCountList(prevCount => prevCount + 1)
    }

    const handleThematicFilterChange = (event) => {
        const value = parseInt(event.target.value);
        const isChecked = event.target.checked;

        if (isChecked) {
            setThematicFilters((prevFilters) => [...prevFilters, value]);
        }
        else {
            setThematicFilters((prevFilters) =>
                prevFilters.filter((filter) => filter !== value)
            );
        }
    }

    return <Fragment>
        <Box>
            <CustomerView customerId={props.customerId}/>
        </Box>
        <Box title="Level">
            <CustomerLevelView customerId={props.customerId}/>
        </Box>
        <Box title="Comments">
            <CustomerCommentList customerId={props.customerId}/>
        </Box>
        <Box title="Figures">
            <CustomerFigureList customerId={props.customerId} triggerHandleStatusIconClickData={triggerHandleStatusIconClickData} submitCount={submitCountList}/>
        </Box>
        <Box title="Figure comments">
            <CustomerFigureCommentHistoryList customerId={props.customerId}/>
        </Box>
        <Box title="Thematics">
            <div>Level</div>
            <div className="flex flex-wrap my-4">
                {thematics &&
                thematics.map((option) => (
                    <label key={option.id} className="flex items-center mr-2" style={{width : "250px", whiteSpace : "nowrap"}}>
                        <input
                            type="checkbox"
                            value={option.id}
                            checked={thematicFilters.includes(option.id)}
                            onChange={handleThematicFilterChange}
                            className="mr-1"
                        />
                        {option.name}
                    </label>
                ))}
            </div>
            {
                customer
                && <FigureProgressTrackerTable
                    customerIds={customerIds}
                    thematicIds={thematicFilters.length === 0 ? null : thematicFilters}
                    handleStatusButtonIconClick={handleStatusButtonIconClick}
                    handleStatusButtonClick={handleStatusButtonClick}
                    showComments={true}
                    submitCount={submitCountTable}
                />
            }
        </Box>
    </Fragment>;
}

export default CustomerDetails;