import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {useUser} from "../../hooks/useUser";
import {convertToDisplayDateTime} from "../../utility/utility";

const UserView = (props) => {
    const {user} = useUser(props.userId, 0);

    return <Fragment>
        <div className="flex justify-between mb-4">
            <Link to={'/users'} className="text-blue-500 hover:underline">Back</Link>
            {
                user
                && (
                    <div className="flex">
                        <h1 className="text-lg font-bold">
                            {user.name}
                        </h1>
                    </div>
                )
            }
        </div>
        {user && (
            <div>
                <div className="mb-2">
                    <span className="font-bold">Email:</span>{' '}
                    <span>{user.email}</span>
                </div>
                <div className="mb-2">
                    <span className="font-bold">Type:</span>{' '}
                    <span>{user.type}</span>
                </div>
                <div className="mb-2">
                    <span className="font-bold">Created at:</span>{' '}
                    <span>{convertToDisplayDateTime(user.createdAt)}</span>
                </div>
            </div>
        )}
    </Fragment>;
}

export default UserView;