import React, {useState} from "react";
import {CUSTOMER_FIGURE_STATUS_ACHIEVED, CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED, CUSTOMER_FIGURE_STATUS_IN_PROGRESS, CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED, CUSTOMER_FIGURE_STATUS_NOTHING, getCustomerFigureDisplayStatus, getCustomerFigureStatusNameById, getNeedToLevelIcon} from "../../utility/utility";
import Button from "../UI/Button";
import {saveCustomerFigure} from "../../api/customerApiClient";
import {toast} from "react-toastify";
import {useFigureProgressTracker} from "../../hooks/useFigureProgressTracker";

const generateTables = (
    customersWithCustomerFigures,
    thematicsWithFigures,
    handleStatusIconClick,
    selectedStatuses,
    clickedCustomerFigure,
    handleConsistentlyAchievedButtonClick,
    handleAchievedButtonClick,
    handleNotAchievedButtonClick,
    handleInProgressButtonClick,
    handleCommentButtonClick,
    showComments
) => {
    const needToLevelIcon = getNeedToLevelIcon();
    const tables = [];

    thematicsWithFigures.forEach((thematicWithFigures) => {
        const tableHeader = [];
        const tableData = [];
        const thematicFigures = thematicWithFigures.thematicRequirements;

        // Filter customers based on assignedCustomerIds array for the specific thematic
        const assignedCustomerIds = thematicWithFigures.assignedCustomerIds;
        const filteredCustomers = assignedCustomerIds.length > 0 ?
            customersWithCustomerFigures.filter(customer => assignedCustomerIds.includes(customer.customerId)) :
            customersWithCustomerFigures;

        // Header
        // Thematic name, ...Customer name
        tableHeader.push(
            <tr className="border-b" key="headers">
                <th className="text-left p-1 px-2">{thematicWithFigures.name}</th>
                {filteredCustomers.map((customerWithFigures) => (
                    <th className="text-left p-1 px-2" key={`${customerWithFigures.customerId}`}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>
                                {`${customerWithFigures.fullName}`}
                            </span>
                            {customerWithFigures.levelName && (
                                <span className="text-xs font-thin">
                                    {customerWithFigures.levelName}
                                </span>
                            )}
                        </div>
                    </th>
                ))}
            </tr>
        );

        // Create table rows with figure name and customer thematicFigures
        thematicFigures.forEach((thematicFigure) => {
            // Check if the selectedStatuses matches the status of at least one customer figure for this thematic figure
            const hasSelectedStatus = filteredCustomers.some((customerWithCustomerFigures) => {
                const customerFigure = customerWithCustomerFigures.figureRequirements.find(
                    (customerFigure) => customerFigure.figureId === thematicFigure.figureId
                );
                const status = customerFigure ? customerFigure.status : CUSTOMER_FIGURE_STATUS_NOTHING;

                return selectedStatuses?.includes(status);
            });

            // If the selectedStatuses does not match, skip this row
            if (selectedStatuses?.length && !hasSelectedStatus) {
                return;
            }

            const rowData = [{name : thematicFigure.name, category : thematicFigure.categoryName, grip : thematicFigure.gripName, level: thematicFigure.levelName, levelRequirements : thematicFigure.levelRequirements}];

            filteredCustomers.forEach((customerWithCustomerFigures) => {
                const customerFigure = customerWithCustomerFigures.figureRequirements.find(
                    (customerFigure) => customerFigure.figureId === thematicFigure.figureId
                );
                const status = customerFigure ? customerFigure.status : null;
                const comment = customerFigure ? customerFigure.comment : null;
                const levelRequirements = customerFigure ? customerFigure.levelRequirements : [];
                rowData.push({status, customerId : customerWithCustomerFigures.customerId, comment : comment, levelRequirements : levelRequirements});
            });

            // Table row
            // Figure, ...customer figure progress
            const row = rowData.map((data, index) => {
                // Figures
                if (index === 0) {
                    const leverReq = thematicFigure.levelRequirements.map(req => `${req.levelName}: ${getCustomerFigureStatusNameById(req.requiredStatus)}`).join(', ');

                    return (
                        <td className="text-left p-1 px-2" key={`${thematicFigure.figureId}-${index}`}>
                            <div>
                                <span>{data.level && needToLevelIcon} {data.name}</span>
                                <span className="text-xs font-thin ml-1">{data.category} {data.grip ? ' | ' + data.grip : null}</span>
                            </div>
                            {leverReq &&
                                <div>
                                    <span className="text-xs font-thin ml-1">{leverReq}</span>
                                </div>
                            }
                        </td>
                    );
                }
                // Customer figures
                else {
                    const isStatusIconClicked =
                        clickedCustomerFigure?.customerId === data.customerId &&
                        clickedCustomerFigure?.figureId === thematicFigure.figureId;

                    const tdBgClass = isStatusIconClicked ? "bg-white-100" : (data.levelRequirements.length > 0 ? "bg-red-50" : "")

                    return (
                        <td
                            onClick={() => handleStatusIconClick(data.customerId, thematicFigure.figureId)}
                            className={`p-1 px-2 text-center ${tdBgClass}`}
                            key={`${thematicFigure.figureId}-${index}`}
                        >
                            <div className="w-full inline-block align-top hover:cursor-pointer">
                                {getCustomerFigureDisplayStatus(data.status)}
                            </div>
                            <div className="relative align-bottom">
                                {showComments && data.comment && <small>{data.comment}</small>}
                            </div>
                            {isStatusIconClicked && (
                                <div className="p-1 px-2 text-center" key={`${thematicFigure.figureId}-${index}-buttons`}>
                                    {data.status !== CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED && (
                                        <Button title="Excellent" handleClick={() => handleConsistentlyAchievedButtonClick(data.customerId, thematicFigure.figureId)}>Excellent</Button>
                                    )}
                                    {data.status !== CUSTOMER_FIGURE_STATUS_ACHIEVED && (
                                        <Button title="Yes" handleClick={() => handleAchievedButtonClick(data.customerId, thematicFigure.figureId)}>Yes</Button>
                                    )}
                                    {data.status !== CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED && (
                                        <Button title="No" handleClick={() => handleNotAchievedButtonClick(data.customerId, thematicFigure.figureId)}>No</Button>
                                    )}
                                    {data.status !== CUSTOMER_FIGURE_STATUS_IN_PROGRESS && (
                                        <Button title="Pending" handleClick={() => handleInProgressButtonClick(data.customerId, thematicFigure.figureId)}>Pending</Button>
                                    )}
                                    <Button title="Comment" handleClick={() => handleCommentButtonClick(data.customerId, thematicFigure.figureId, data.status)}>Comment</Button>
                                </div>
                            )}
                        </td>
                    );
                }
            });
            tableData.push(<tr className="border-b hover:bg-gray-100" key={thematicFigure.figureId}>{row}</tr>);
        });

        // Add table to tables array
        const table = (
            <div style={{ maxHeight: '640px' }} className="overflow-y-auto" key={thematicWithFigures.thematicId}>
                <table className="bg-white shadow-md rounded my-4">
                    <thead className="sticky top-0 bg-white">{tableHeader}</thead>
                    <tbody>{tableData}</tbody>
                </table>
            </div>
        );
        tables.push(table);
    });

    return tables;
};

const
    FigureProgressTrackerTable = (props) => {
    const [clickedCustomerFigure, setClickedCustomerFigure] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const {customersWithCustomerFigures, thematicsWithFigures} = useFigureProgressTracker(
        props.customerIds,
        props.thematicIds,
        +props.showComments + submitCount + props.submitCount
    );

    const handleStatusIconClick = (customerId, figureId) => {
        const isClicked =
            clickedCustomerFigure?.customerId === customerId && clickedCustomerFigure?.figureId === figureId;

        if (isClicked) {
            setClickedCustomerFigure(null);
        }
        else {
            setClickedCustomerFigure({customerId, figureId});
        }
    };

    const handleConsistentlyAchievedButtonClick = (customerId, figureId) => {
        saveCustomerFigure(customerId, figureId, CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED, new Date().toISOString().substr(0, 10), null)
            .then(() => {
                setClickedCustomerFigure(null);
                setSubmitCount((prevCount) => prevCount + 1);
                toast.success("Success!");

                if (props?.handleStatusButtonClick) {
                    props.handleStatusButtonClick();
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const handleAchievedButtonClick = (customerId, figureId) => {
        saveCustomerFigure(customerId, figureId, CUSTOMER_FIGURE_STATUS_ACHIEVED, new Date().toISOString().substr(0, 10), null)
            .then(() => {
                setClickedCustomerFigure(null);
                setSubmitCount((prevCount) => prevCount + 1);
                toast.success("Success!");

                if (props?.handleStatusButtonClick) {
                    props.handleStatusButtonClick();
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const handleNotAchievedButtonClick = (customerId, figureId) => {
        saveCustomerFigure(customerId, figureId, CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED, new Date().toISOString().substr(0, 10), null)
            .then(() => {
                setClickedCustomerFigure(null);
                setSubmitCount((prevCount) => prevCount + 1);
                toast.success("Success!");

                if (props?.handleStatusButtonClick) {
                    props.handleStatusButtonClick();
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const handleInProgressButtonClick = (customerId, figureId) => {
        saveCustomerFigure(customerId, figureId, CUSTOMER_FIGURE_STATUS_IN_PROGRESS, new Date().toISOString().substr(0, 10), null)
            .then(() => {
                setClickedCustomerFigure(null);
                setSubmitCount((prevCount) => prevCount + 1);
                toast.success("Success!");

                if (props?.handleStatusButtonClick) {
                    props.handleStatusButtonClick();
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    const handleCommentButtonClick = (customerId, figureId, currentStatus) => {
        setClickedCustomerFigure(null);
        props.handleStatusButtonIconClick(customerId, figureId, currentStatus);
    };

    const tables = generateTables(
        customersWithCustomerFigures,
        thematicsWithFigures,
        handleStatusIconClick,
        props.selectedStatues,
        clickedCustomerFigure,
        handleConsistentlyAchievedButtonClick,
        handleAchievedButtonClick,
        handleNotAchievedButtonClick,
        handleInProgressButtonClick,
        handleCommentButtonClick,
        props.showComments
    );

    return <div className="overflow-x-auto">{tables}</div>;
};

export default FigureProgressTrackerTable;
