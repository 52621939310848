import React, {Fragment, useState} from 'react';
import ListEdit from "../UI/ListEdit";
import {useNavigate} from 'react-router-dom';
import Box from "../UI/Box";
import {useCustomerLevelRequirementSummaries} from "../../hooks/useCustomerLevelRequirementSummaries";
import {notReadyToNextLevelIcon, readyToNextLevelIcon} from "../../utility/utility";

const CustomerLevelRequirementSummaryList = (props) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const {customerLevelRequirementSummaries, pagination} = useCustomerLevelRequirementSummaries(page, pageSize);
    const navigate = useNavigate();

    const customerLevelRequirementSummaryList = customerLevelRequirementSummaries.map((item) => {
        return {
            customerId              : item.customer.id,
            fullName                : item.customer.firstName + ' ' + item.customer.lastName,
            levelName               : item.customer.level?.name,
            nextLevelName           : item.nextLevel?.name,
            missingRequirementCount : item.missingRequirementCount,
            readyToNextLevel        : item.missingRequirementCount === 0 ? readyToNextLevelIcon : notReadyToNextLevelIcon,
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleShowIconClick = (customerId) => {
        navigate('/customer-level-requirements/' + customerId);
    }

    const listHeaders = [
        'Name',
        'Actual level',
        'Next level',
        'Ready to next level',
        'Missing figure requirements'
    ];

    const columns = {
        functionParams : ['customerId'],
        dataKeys       : [
            'fullName',
            'levelName',
            'nextLevelName',
            'readyToNextLevel',
            'missingRequirementCount'
        ],
    }

    return <Fragment>
        <Box>
            <ListEdit
                headers={listHeaders}
                items={customerLevelRequirementSummaryList}
                columns={columns}
                pagination={pagination}
                handleShowIconClick={handleShowIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
    </Fragment>;
}

export default CustomerLevelRequirementSummaryList;