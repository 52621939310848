import React from "react";
import {Outlet, Navigate} from "react-router-dom";
import {useStateContext} from "../../contexts/ContextProvider";
import {ToastContainer} from "react-toastify";

export default function GuestLayout() {
    const {token} = useStateContext();

    if (token) {
        return <Navigate to='/'/>
    }

    return <div>
        <Outlet/>
        <ToastContainer />
    </div>
}