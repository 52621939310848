import ListEdit from "../UI/ListEdit";
import {useExamRequirements} from "../../hooks/useExamRequirements";
import {Link} from "react-router-dom";
import React, {Fragment, useRef, useState} from "react";
import {useExam} from "../../hooks/useExam";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import {createExamRequirement, deleteExamRequirement, findExamNotAssignedFiguresWithPagination, getExamRequirement, updateExamRequirement} from "../../api/examApiClient";
import Box from "../UI/Box";
import {toast} from "react-toastify";
import {DEFAULT_EXAM_REQUIREMENT_MIN_STATUS, getCustomerFigureStatuses, getCustomerFigureStatusNameById} from "../../utility/utility";

const ExamRequirementsList = (props) => {
    const {exam} = useExam(props.examId);
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [figures, setFigures] = useState(null);
    const [examRequirement, setExamRequirement] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const {examRequirements, pagination} = useExamRequirements(props.examId, page, pageSize, submitCount);
    const selectRef = useRef(null);
    const minStatusRef = useRef(null);
    const [statuses] = useState(getCustomerFigureStatuses());

    const examRequirementList = examRequirements.map((item) => {
        const categoryNames = item.figure.categories.map(function (category, index) {
            return category.name;
        }).join(', ');

        const minStatus = getCustomerFigureStatusNameById(item.minStatus);

        return {examId : item.examId, figureId : item.figure.figureId, name : item.figure.name, categories : categoryNames, minStatus : minStatus};
    });

    const listHeaders = [
        'Name',
        'Categories',
        'Min. Status'
    ];

    const columns = {
        functionParams : [
            'examId', 'figureId'
        ],
        dataKeys       : [
            'name',
            'categories',
            'minStatus'
        ]
    }

    const handleAddRequirementButtonClick = () => {
        const figuresWithPagination = findExamNotAssignedFiguresWithPagination(props.examId);

        figuresWithPagination
            .then(
                (data) => {
                    const figures = data.figures;

                    setFigures(figures)
                    setIsUpdate(false)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );

    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleAddSubmit = (event) => {
        event.preventDefault();
        const figureId = selectRef.current.value;
        const minStatus = minStatusRef.current.value;

        createExamRequirement(props.examId, figureId, minStatus)
            .then(() => {
                setShowModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditIconClick = (examId, figureId) => {
        setIsUpdate(true);
        const examRequirement = getExamRequirement(examId, figureId);

        examRequirement
            .then(
                (data) => {
                    setExamRequirement(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleDeleteIconClick = (examId, figureId) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Are you sure?')) {
            deleteExamRequirement(examId, figureId)
                .then(() => {
                    setSubmitCount(prevCount => prevCount + 1);
                    toast.success('Success!');
                })
                .catch(
                    (error) => {
                        toast.error(error.message);
                    }
                );
        }
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const minStatus = minStatusRef.current.value;

        updateExamRequirement(props.examId, examRequirement.figure.figureId, minStatus)
            .then(() => {
                setShowModal(false);
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    return (
        <Fragment>
            <Link to={'/exams'}>Back</Link>
            <Box>
                {exam && <h1>Exam: {exam.name}</h1>}
                <Button title='Add figure requirement' handleClick={handleAddRequirementButtonClick}/>
                <ListEdit
                    headers={listHeaders}
                    items={examRequirementList}
                    columns={columns}
                    pagination={pagination}
                    handleEditIconClick={handleEditIconClick}
                    handleDeleteIconClick={handleDeleteIconClick}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                />
            </Box>
            {showModal && (
                <Modal>
                    <form onSubmit={isUpdate ? handleEditSubmit : handleAddSubmit}>
                        <ModalForm
                            title={isUpdate ? 'Update min. status' : 'Add figure requirement'}
                            saveButtonTitle={isUpdate ? 'Update' : 'Add'}
                            handleCancelButtonClick={() => setShowModal(false)}
                        >
                            <ModalFormInputRow title='Figure'>
                                {
                                    !isUpdate && figures && <select ref={selectRef} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                                        {
                                            figures.map(
                                                (option) => (
                                                    <option key={option.figureId} value={option.figureId}>
                                                        {option.name} ({option.categories.map((category) => category.name).join(', ')}) {option.grip !== null ? ` ${option.grip.name}` : null}
                                                    </option>
                                                )
                                            )
                                        }
                                    </select>
                                }
                                {
                                    isUpdate && examRequirement && <input
                                        type="text"
                                        id="figureName"
                                        name="figureName"
                                        defaultValue={examRequirement.figure.name}
                                        readOnly={true}
                                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                    />
                                }
                            </ModalFormInputRow>
                            <ModalFormInputRow title='Min. Status'>
                                {statuses && <select ref={minStatusRef} defaultValue={isUpdate && examRequirement ? examRequirement.minStatus : DEFAULT_EXAM_REQUIREMENT_MIN_STATUS} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                                    {
                                        statuses.map(
                                            (option) => (
                                                <option key={option.id} value={option.id}>{option.name}</option>
                                            )
                                        )
                                    }
                                </select>}
                            </ModalFormInputRow>
                        </ModalForm>
                    </form>
                </Modal>
            )}
        </Fragment>

    );
};

export default ExamRequirementsList;