import {useCallback, useEffect, useState} from "react";
import {getLevel} from "../api/levelApiClient";

export const useLevel = (levelId) => {
    const [level, setLevel] = useState(null);

    const fetchLevel = useCallback(async () => {
        try {
            const data = await getLevel(levelId);
            setLevel(data);
        }
        catch (error) {
            console.log(error);
        }
    }, [levelId]);

    useEffect(() => {
        fetchLevel();
    }, [fetchLevel]);

    return {level : level};
};