import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Modal from "../UI/Modal";
import React, {useRef} from "react";
import {toast} from "react-toastify";
import {createUserAssignment, updateUserAssignment} from "../../api/userAssignmentApiClient";


const UserAssignmentModal = (props) => {
    const isUpdate = props.isUpdate;
    const customers = props.customers;
    const thematics = props.thematics;
    const defaultModalCustomerValue = props.defaultModalCustomerValue;
    const userAssignment = props.userAssignment;
    const userId = props.userId;
    const handleCancel = props.handleCancel;

    const customerRef = useRef(null);
    const selectRef = useRef(null);

    const handleAddSubmit = (event) => {
        event.preventDefault();

        const customerId = customerRef.current.value;
        const selectedValues = Array.from(selectRef.current.selectedOptions).map(option => option.value);

        createUserAssignment(userId, customerId, selectedValues)
            .then(() => {
                props.handleAddSubmit();
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const selectedValues = Array.from(selectRef.current.selectedOptions).map(option => option.value);

        updateUserAssignment(userId, userAssignment.customer.customerId, selectedValues)
            .then(() => {
                props.handleEditSubmit();
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    return <Modal>
        <form onSubmit={isUpdate ? handleEditSubmit : handleAddSubmit}>
            <ModalForm
                title={isUpdate ? 'Update thematics' : 'Add assignment'}
                saveButtonTitle={isUpdate ? 'Update' : 'Add'}
                handleCancelButtonClick={handleCancel}
            >
                <ModalFormInputRow title='Customer'>
                    {
                        !isUpdate && customers && <select defaultValue={defaultModalCustomerValue} ref={customerRef} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                            {
                                customers.map(
                                    (option) => (
                                        <option key={option.customerId} value={option.customerId}>{option.firstName + ' ' + option.lastName}</option>
                                    )
                                )
                            }
                        </select>
                    }
                    {
                        isUpdate && userAssignment && <input
                            type="text"
                            id="customerName"
                            name="customerName"
                            defaultValue={userAssignment.customer.firstName + ' ' + userAssignment.customer.lastName}
                            readOnly={true}
                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                        />
                    }
                </ModalFormInputRow>
                <ModalFormInputRow title='Thematics'>
                    {thematics && <select defaultValue={isUpdate ? userAssignment.thematics.map(obj => obj.thematicId) : null} ref={selectRef} multiple={true} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                        {
                            thematics.map(
                                (option) => (
                                    <option key={option.thematicId} value={option.thematicId}>{option.name}</option>
                                )
                            )
                        }
                    </select>}
                </ModalFormInputRow>
            </ModalForm>
        </form>
    </Modal>
}

export default UserAssignmentModal;