import {useEffect, useState} from "react";
import {findCustomerCommentsWithPagination} from "../api/customerApiClient";

export const useCustomerComments = (customerId, page, pageSize, reloadTrigger) => {
    const [customerComments, setCustomerComments] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const customerCommentsWithPagination = findCustomerCommentsWithPagination(page, pageSize, customerId, abortController.signal);

        customerCommentsWithPagination
            .then(
                (data) => {
                    const customerComments = data.customerComments;
                    const pagination = data.pagination;

                    setCustomerComments(customerComments)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerId, page, pageSize, reloadTrigger]);

    return {customerComments : customerComments, pagination};
};