import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Modal from "../UI/Modal";
import React, {useRef} from "react";
import {toast} from "react-toastify";
import {changePassword} from "../../api/userApiClient";

const ChangePasswordModal = (props) => {
    const userId = props.userId;
    const handleCancel = props.handleCancel;

    const passwordRef = useRef(null);
    const passwordConfirmationRef = useRef(null);

    const handleChangeSubmit = (event) => {
        event.preventDefault();

        const password = passwordRef.current.value;
        const passwordConfirmation = passwordConfirmationRef.current.value;

        changePassword(userId, password, passwordConfirmation)
            .then(() => {
                props.handleChangeSubmit();
                toast.success('Success!');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    return <Modal>
        <form onSubmit={handleChangeSubmit}>
            <ModalForm
                title={'Change password'}
                saveButtonTitle={'Change'}
                handleCancelButtonClick={handleCancel}
            >
                <ModalFormInputRow title='Password'>
                    <input
                        ref={passwordRef}
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Enter password"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
                <ModalFormInputRow title='Confirm password'>
                    <input
                        ref={passwordConfirmationRef}
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        placeholder="Confirm password"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
            </ModalForm>
        </form>
    </Modal>
}

export default ChangePasswordModal;