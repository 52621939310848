import {API_URL, handleErrorResponse} from "../utility/utility";

export async function findThematicsWithPagination(page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/thematics?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch thematics');

    return response.json();
}

export async function createThematic(name) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/thematics`, {
        method  : 'POST',
        body    : JSON.stringify({
            name : name
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to create thematic');
}

export async function updateThematic(thematicId, name) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/thematics/${thematicId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            name : name,
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to update thematic');
}

export async function getThematic(thematicId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/thematics/${thematicId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch thematic');

    return await response.json();
}

export async function findThematicFiguresWithPagination(thematicId, page, limit, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', page);
    params.append('limit', limit);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/thematic-figures/${thematicId}?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch thematic figures');

    return response.json();
}

export async function findThematicNotAssignedFiguresWithPagination(thematicId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const params = new URLSearchParams();
    params.append('page', 1);
    params.append('limit', 1000);
    const queryString = params.toString();

    const response = await fetch(`${API_URL}/api/thematic-figures/${thematicId}/not-assigned?${queryString}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch not assigned figures');

    return response.json();
}

export async function createThematicFigure(thematicId, figureId, order) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/thematic-figures`, {
        method  : 'POST',
        body    : JSON.stringify({
            thematicId : thematicId,
            figureId   : figureId,
            order      : order
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to assign figure to thematic');
}

export async function updateThematicFigure(thematicId, figureId, order) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/thematic-figures/${thematicId}/${figureId}`, {
        method  : 'PATCH',
        body    : JSON.stringify({
            order : order
        }),
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        }
    });

    await handleErrorResponse(response, 'Failed to move figure to another place');
}

export async function getThematicFigure(thematicId, figureId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/thematic-figures/${thematicId}/${figureId}`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch thematic figure');

    return await response.json();
}

export async function deleteThematicFigure(thematicId, figureId, signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/thematic-figures/${thematicId}/${figureId}`, {
        method  : 'DELETE',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to delete thematic figure');
}