import React, {Fragment, useState} from "react";
import Button from "../UI/Button";
import ListEdit from "../UI/ListEdit";
import {getCustomerComment} from "../../api/customerApiClient";
import {useCustomerComments} from "../../hooks/useCustomerComments";
import CustomerCommentModal from "./CustomerCommentModal";
import {convertToDisplayDateTime} from "../../utility/utility";

const CustomerCommentList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [submitCount, setSubmitCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [customerComment, setCustomerComment] = useState([]);
    const {customerComments, pagination} = useCustomerComments(props.customerId, page, pageSize, submitCount);

    const customerCommentList = customerComments.map((item) => {
        return {
            customerCommentId : item.customerCommentId,
            createdAt         : convertToDisplayDateTime(item.createdAt),
            updatedAt         : item.updatedAt && convertToDisplayDateTime(item.updatedAt),
            comment           : item.comment
        };
    });

    const listHeaders = [
        'Comment',
        'Created at',
        'Updated at',
        'Actions'
    ];

    const columns = {
        functionParams : [
            'customerCommentId'
        ],
        dataKeys       : [
            'comment',
            'createdAt',
            'updatedAt'
        ],
    }

    const handleAddCommentButtonClick = () => {
        setIsUpdate(false);
        setShowModal(true);
    }

    const handleEditButtonIconClick = (customerCommentId) => {
        setIsUpdate(true);
        const customerComment = getCustomerComment(customerCommentId, props.customerId);

        customerComment
            .then(
                (data) => {
                    setCustomerComment(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => {
                    if (error.status === 400 && error.code === 101) {
                        console.log(error)
                    }
                    else {
                        console.log(error)
                    }
                }
            );
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleAddSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    const handleEditSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    return <Fragment>
        <Button title='Add comment' handleClick={handleAddCommentButtonClick}/>
        <ListEdit
            headers={listHeaders}
            items={customerCommentList}
            columns={columns}
            pagination={pagination}
            handleEditIconClick={handleEditButtonIconClick}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
        />
        {
            showModal && <CustomerCommentModal
                isUpdate={isUpdate}
                handleAddSubmit={handleAddSubmit}
                handleEditSubmit={handleEditSubmit}
                handleCancel={function () {
                    setShowModal(false);
                }}
                customerComment={customerComment}
                customerId={props.customerId}
            />
        }
    </Fragment>
}

export default CustomerCommentList;