import React, {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import ListEdit from "../UI/ListEdit";
import {useCustomers} from "../../hooks/useCustomers";
import {useNavigate} from 'react-router-dom';
import Box from "../UI/Box";
import CustomerModal from "./CustomerModal";
import {getCustomerStatuses} from "../../utility/utility";

const CustomerList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [customer, setCustomer] = useState([]);
    const [submitCount, setSubmitCount] = useState(0);
    const [filterFirstName, setFilterFirstName] = useState(null);
    const [filterLastName, setFilterLastName] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);
    const firstNameFilterRef = useRef(null);
    const lastNameFilterRef = useRef(null);
    const selectStatusFilterRef = useRef(null);
    const {customers, pagination} = useCustomers(page, pageSize, filterFirstName, filterLastName, filterStatus, submitCount);
    const navigate = useNavigate();

    const customerList = customers.map((item) => {
        return {
            customerId : item.id,
            firstName  : item.firstName,
            lastName   : item.lastName,
            status     : item.status,
            level      : item.level?.name,
        };
    });

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleCreateButtonClick = (customerId) => {
        setIsUpdate(false);
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    const handleEditSubmit = () => {
        setShowModal(false)
        setSubmitCount(prevCount => prevCount + 1);
    }

    const handleFirstNameFilterChange = () => {
        setPage(1);
        setFilterFirstName(firstNameFilterRef.current.value)
    }

    const handleLastNameFilterChange = () => {
        setPage(1);
        setFilterLastName(lastNameFilterRef.current.value)
    }

    const handleStatusFilterChange = () => {
        setFilterStatus(selectStatusFilterRef.current.value)
        setPage(1);
    }

    const handleShowIconClick = (customerId) => {
        navigate('/customers/' + customerId);
    }

    const listHeaders = [
        'Id',
        'Fist name',
        'Last name',
        'Status',
        'Level',
        'Actions'
    ];

    const columns = {
        functionParams : ['customerId'],
        dataKeys       : [
            'customerId',
            'firstName',
            'lastName',
            'status',
            'level'
        ],
    }

    return <Fragment>
        <Box>
            <Button title='Create customer' handleClick={handleCreateButtonClick}/>
            <div className="flex my-4">
                <input ref={firstNameFilterRef} type="text" placeholder="Filter by first name" className="border p-2 mr-2" onChange={handleFirstNameFilterChange}/>
                <input ref={lastNameFilterRef} type="text" placeholder="Filter by last name" className="border p-2 mr-2" onChange={handleLastNameFilterChange}/>
                <select ref={selectStatusFilterRef} onChange={handleStatusFilterChange} className="border p-2 mr-2">
                    <option value="">Filter by status</option>
                    {
                        getCustomerStatuses().map(
                            (option) => (
                                <option key={option} value={option}>{option}</option>
                            )
                        )
                    }
                </select>
            </div>
            <ListEdit
                headers={listHeaders}
                items={customerList}
                columns={columns}
                pagination={pagination}
                handleShowIconClick={handleShowIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {
            showModal
            && <CustomerModal
                isUpdate={isUpdate}
                handleEditSubmit={handleEditSubmit}
                handleCreateSubmit={handleCreateSubmit}
                handleCancel={function () {
                    setShowModal(false)
                }}
                customer={customer}
            />
        }
    </Fragment>;
}

export default CustomerList;