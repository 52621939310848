import {useEffect, useState} from "react";
import {findFiguresWithPagination} from "../api/figureApiClient";

export const useFigures = (page, pageSize, filterName, filterCategory, filterGrip, reloadTrigger) => {
    const [figures, setFigures] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const figuresWithPagination = findFiguresWithPagination(page, pageSize, filterName, filterCategory, filterGrip, abortController.signal);

        figuresWithPagination
            .then(
                (data) => {
                    const figures = data.figures;
                    const pagination = data.pagination;

                    setFigures(figures)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }, [filterName, filterCategory, filterGrip, page, pageSize, reloadTrigger]);

    return {figures, pagination};
};