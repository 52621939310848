import {useCallback, useEffect, useState} from "react";
import {getDashboard} from "../api/dashboardApiClient";

export const useDashboard = (reloadTrigger) => {
    const [statusCounts, setStatusCounts] = useState(null);
    const [levelCounts, setLevelCounts] = useState(null);
    const [customerLevelRequirements, setCustomerLevelRequirements] = useState(null);

    const fetchDashboard = useCallback(async () => {
        try {
            const data = await getDashboard();
            setStatusCounts(data.statusCounts);
            setLevelCounts(data.levelCounts);
            setCustomerLevelRequirements(data.customerLevelRequirements);
        }
        catch (error) {
            console.log(error);
        }
    }, [reloadTrigger]);

    useEffect(() => {
        fetchDashboard();
    }, [fetchDashboard]);

    return {statusCounts : statusCounts, levelCounts : levelCounts, customerLevelRequirements : customerLevelRequirements};
};