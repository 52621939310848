import React, {useRef} from "react";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import {createCustomer, updateCustomer} from "../../api/customerApiClient";
import {toast} from "react-toastify";
import {getCustomerStatuses} from "../../utility/utility";

const CustomerModal = (props) => {
    const isUpdate = props.isUpdate;
    const handleCancel = props.handleCancel;
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const statusRef = useRef(null);
    const customer = props.customer;

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const email = emailRef.current.value;
        const phone = phoneRef.current.value;
        const status = statusRef.current.value;

        createCustomer(firstName, lastName, email, phone, status)
            .then(() => {
                props.handleCreateSubmit();
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const firstName = firstNameRef.current.value;
        const lastName = lastNameRef.current.value;
        const email = emailRef.current.value;
        const phone = phoneRef.current.value;
        const status = statusRef.current.value;

        updateCustomer(customer.id, firstName, lastName, email, phone, status)
            .then(() => {
                props.handleEditSubmit(firstName, lastName, email, phone, status);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    return <Modal>
        <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
            <ModalForm
                title={isUpdate ? 'Update customer' : 'Create customer'}
                saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                handleCancelButtonClick={handleCancel}
            >
                <ModalFormInputRow title='First name'>
                    <input
                        ref={firstNameRef}
                        type="text"
                        id="firstName"
                        name="firstName"
                        defaultValue={isUpdate ? customer.firstName : null}
                        placeholder="Enter first name"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
                <ModalFormInputRow title='Last name'>
                    <input
                        ref={lastNameRef}
                        type="text"
                        id="lastName"
                        name="lastName"
                        defaultValue={isUpdate ? customer.lastName : null}
                        placeholder="Enter last name"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
                <ModalFormInputRow title='Email'>
                    <input
                        ref={emailRef}
                        type="email"
                        id="email"
                        name="email"
                        defaultValue={isUpdate ? customer.email : null}
                        placeholder="Enter email"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
                <ModalFormInputRow title='Phone'>
                    <input
                        ref={phoneRef}
                        type="text"
                        id="phone"
                        name="phone"
                        defaultValue={isUpdate ? customer.phone : null}
                        placeholder="Enter phone"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
                <ModalFormInputRow title='Status'>
                    <select ref={statusRef} defaultValue={customer.status} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                        {
                            getCustomerStatuses().map(
                                (option) => (
                                    <option key={option} value={option}>{option}</option>
                                )
                            )
                        }
                    </select>
                </ModalFormInputRow>
            </ModalForm>
        </form>
    </Modal>
}

export default CustomerModal;