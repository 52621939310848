import {API_URL, handleErrorResponse} from "../utility/utility";

export async function getDashboard(signal = null) {
    const token = localStorage.getItem('ACCESS_TOKEN');

    const response = await fetch(`${API_URL}/api/dashboard`, {
        method  : 'GET',
        headers : {
            'Accept'        : 'application/json',
            'Content-Type'  : 'application/json',
            'Authorization' : 'Bearer ' + token
        },
        signal  : signal
    });

    await handleErrorResponse(response, 'Failed to fetch dashboard');

    return await response.json();
}