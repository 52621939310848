import {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import React from "react";
import Modal from "../UI/Modal";
import {createFigureCategory, getFigureCategory, updateFigureCategory} from "../../api/figureApiClient";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import {useFigureCategories} from "../../hooks/useFigureCategories";
import Box from "../UI/Box";
import {toast} from "react-toastify";

const FigureCategoryList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [figureCategory, setFigureCategory] = useState(null);
    const [submitCount, setSubmitCount] = useState(0);
    const nameRef = useRef(null);
    const {figureCategories, pagination} = useFigureCategories(page, pageSize, submitCount);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleEditIconClick = (figureCategoryId) => {
        setIsUpdate(true);
        const figureCategory = getFigureCategory(figureCategoryId);

        figureCategory
            .then(
                (data) => {
                    setFigureCategory(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleCreateButtonClick = (figureCategoryId) => {
        setIsUpdate(false);
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        const name = nameRef.current.value;

        createFigureCategory(name)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const name = nameRef.current.value;

        updateFigureCategory(figureCategory.figureCategoryId, name)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const listHeaders = [
        'Id',
        'Name',
        'Actions'
    ];

    const columns = {
        functionParams : ['figureCategoryId'],
        dataKeys     : [
            'figureCategoryId',
            'name'
        ],
    }

    return <Fragment>
        <Box>
            <Button title='Create figure category' handleClick={handleCreateButtonClick}/>
            <ListEdit
                headers={listHeaders}
                items={figureCategories}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {showModal && (
            <Modal>
                <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
                    <ModalForm
                        title={isUpdate ? 'Update figure category' : 'Create figure category'}
                        saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                        handleCancelButtonClick={() => setShowModal(false)}
                    >
                        <ModalFormInputRow title='Name'>
                            <input
                                ref={nameRef}
                                type="text"
                                id="name"
                                name="name"
                                defaultValue={isUpdate ? figureCategory.name : null}
                                placeholder="Enter name"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                    </ModalForm>
                </form>
            </Modal>
        )}
    </Fragment>;
}

export default FigureCategoryList;