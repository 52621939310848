import React, {Fragment} from "react";
import {useDashboard} from "../../hooks/useDashboard";
import {CUSTOMER_STATUS_ACTIVE} from "../../utility/utility";

const Dashboard = (props) => {
    const {statusCounts, levelCounts, customerLevelRequirements} = useDashboard();

    function getStatusCount(jsonData, targetStatus) {
        // Loop through the array of objects in the JSON
        for (let i = 0; i < jsonData.length; i++) {
            // Check if the status of the current object matches the targetStatus
            if (jsonData[i].status === targetStatus) {
                // Increment the count if there is a match
                return jsonData[i].count;
            }
        }

        return 0;
    }

    return <Fragment>
        <div className="my-3 rounded-md bg-gray-100 p-4">
            <div className="grid grid-cols-3 gap-4">
                {statusCounts && <div className="rounded bg-white p-4 text-center shadow-md">
                    <p className="text-lg font-bold mb-2">Active customers</p>
                    <p className="text-lg font-bold text-green-500">{getStatusCount(statusCounts, CUSTOMER_STATUS_ACTIVE)}</p>
                </div>}

                {levelCounts && <div className="flex flex-col space-y-2 bg-white p-4 rounded shadow-md">
                    <p className="text-lg font-bold mb-2">Levels</p>
                    <table className="min-w-full">
                        <tbody>
                            {
                                levelCounts.map(
                                    (levelCount) => (
                                        <tr key={levelCount.level} className="text-sm">
                                            <td className="py-1 px-2">{levelCount.level ?? 'NO LEVEL'}</td>
                                            <td className="py-1 px-2 text-gray-500">{levelCount.count}</td>
                                        </tr>
                                    )
                                )
                            }
                        </tbody>
                    </table>
                </div>}
                {customerLevelRequirements && <div className="flex flex-col space-y-2 bg-white p-4 rounded shadow-md">
                    <p className="text-lg font-bold mb-2">Level requirements</p>
                    <table className="min-w-full">
                        <tbody>
                            {
                                customerLevelRequirements.map(
                                    (customerLevelRequirement) => (
                                        <tr key={customerLevelRequirement.name} className="text-sm">
                                            <td className="py-1 px-2">{customerLevelRequirement.name}</td>
                                            <td className="py-1 px-2 text-gray-500">{customerLevelRequirement.nextLevel}</td>
                                            <td className="py-1 px-2 text-gray-500">{customerLevelRequirement.requirementCount}</td>
                                        </tr>
                                    )
                                )
                            }
                        </tbody>
                    </table>
                </div>}
            </div>
        </div>
    </Fragment>;
}

export default Dashboard;