import React from "react";
import {useParams} from 'react-router-dom';
import LevelRequirementsList from "../../components/level/LevelRequirementsList";

const LevelRequirementsPage = () => {
    const {levelId} = useParams();

    return <LevelRequirementsList levelId={levelId}/>
}

export default LevelRequirementsPage;