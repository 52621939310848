import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Modal from "../UI/Modal";
import React, {useRef} from "react";
import {saveCustomerFigure} from "../../api/customerApiClient";
import {CUSTOMER_FIGURE_STATUS_ACHIEVED, CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED, CUSTOMER_FIGURE_STATUS_IN_PROGRESS, CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED} from "../../utility/utility";
import { toast } from "react-toastify";

const CustomerFigureModal = (props) => {
    const isUpdate = props.isUpdate;
    const figures = props.figures;
    const defaultModalFigureValue = props.defaultModalFigureValue;
    const defaultModalStatusValue = props.defaultModalStatusValue ?? CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED;
    const customerFigure = props.customerFigure;
    const customerId = props.customerId;
    const handleCancel = props.handleCancel;

    const figureRef = useRef(null);
    const statusRef = useRef(null);
    const happenedAtRef = useRef(null);
    const commentRef = useRef(null);

    const handleAddSubmit = (event) => {
        event.preventDefault();

        const figureId = figureRef.current.value;
        const status = statusRef.current.value;
        const happenedAt = happenedAtRef.current.value;
        const comment = commentRef.current.value;

        saveCustomerFigure(customerId, figureId, status, happenedAt, comment)
            .then(() => {
                props.handleAddSubmit();
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();

        const status = statusRef.current.value;
        const happenedAt = happenedAtRef.current.value;
        const comment = commentRef.current.value;

        saveCustomerFigure(customerFigure.customerId, customerFigure.figure.figureId, status, happenedAt, comment)
            .then(() => {
                props.handleEditSubmit();
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    return <Modal>
        <form onSubmit={isUpdate ? handleEditSubmit : handleAddSubmit}>
            <ModalForm
                title={isUpdate ? 'Update status' : 'Add figure'}
                saveButtonTitle={isUpdate ? 'Update' : 'Add'}
                handleCancelButtonClick={handleCancel}
            >
                <ModalFormInputRow title='Figure'>
                    {
                        !isUpdate && figures && <select defaultValue={defaultModalFigureValue} ref={figureRef} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                            {
                                figures.map(
                                    (option) => (
                                        <option key={option.figureId} value={option.figureId}>{option.name}</option>
                                    )
                                )
                            }
                        </select>
                    }
                    {
                        isUpdate && customerFigure && <input
                            type="text"
                            id="figureName"
                            name="figureName"
                            defaultValue={customerFigure.figure.name}
                            readOnly={true}
                            className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                        />
                    }
                </ModalFormInputRow>
                <ModalFormInputRow title='Status'>
                    <select
                        ref={statusRef}
                        id='status'
                        name='status'
                        defaultValue={defaultModalStatusValue}
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    >
                        <option key={1} value={CUSTOMER_FIGURE_STATUS_NOT_ACHIEVED}>Not Achieved</option>
                        <option key={2} value={CUSTOMER_FIGURE_STATUS_IN_PROGRESS}>In progress</option>
                        <option key={3} value={CUSTOMER_FIGURE_STATUS_ACHIEVED}>Achieved</option>
                        <option key={4} value={CUSTOMER_FIGURE_STATUS_CONSISTENTLY_ACHIEVED}>Consistently Achieved</option>
                    </select>
                </ModalFormInputRow>
                <ModalFormInputRow title='Happened At'>
                    <input
                        ref={happenedAtRef}
                        type="date"
                        id="happenedAt"
                        name="happenedAt"
                        defaultValue={isUpdate ? customerFigure.happenedAt : new Date().toISOString().substr(0, 10)}
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
                <ModalFormInputRow title='Comment'>
                    <textarea
                        ref={commentRef}
                        id="comment"
                        name="comment"
                        defaultValue={isUpdate ? customerFigure.comment : null}
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
            </ModalForm>
        </form>
    </Modal>
}

export default CustomerFigureModal;