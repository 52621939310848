import ThematicList from "../../components/thematic/ThematicList";
import {Fragment} from "react";

const ThematicsPage = () => {
    return <Fragment>
        <ThematicList/>
    </Fragment>
}

export default ThematicsPage;
