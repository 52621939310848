import ExamList from "../../components/exam/ExamList";
import {Fragment} from "react";

const ExamsPage = () => {
    return <Fragment>
        <ExamList/>
    </Fragment>
}

export default ExamsPage;
