import React, {Fragment, useRef, useState} from 'react';
import {createFigure, getFigure, updateFigure} from "../../api/figureApiClient";
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import ListEdit from "../UI/ListEdit";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import ModalForm from "../UI/ModalForm";
import {useFigures} from "../../hooks/useFigures";
import {useFigureCategories} from "../../hooks/useFigureCategories";
import Box from "../UI/Box";
import {useGrips} from "../../hooks/useGrips";
import {toast} from "react-toastify";

const FigureList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [figure, setFigure] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [submitCount, setSubmitCount] = useState(0);
    const nameRef = useRef(null);
    const selectRef = useRef(null);
    const selectGripRef = useRef(null);
    const [filterName, setFilterName] = useState(null);
    const [filterCategory, setFilterCategory] = useState(null);
    const [filterGrip, setFilterGrip] = useState(null);
    const nameFilterRef = useRef(null);
    const selectFilterRef = useRef(null);
    const selectGripFilterRef = useRef(null);
    const {figures, pagination} = useFigures(page, pageSize, filterName, filterCategory, filterGrip, submitCount);
    const {figureCategories} = useFigureCategories(1, 100, 0);
    const {grips} = useGrips(1, 100, 0);

    const handleCreateButtonClick = () => {
        setIsUpdate(false);
        setShowModal(true)
    }

    const handleEditIconClick = (figureId) => {
        setIsUpdate(true);
        const figure = getFigure(figureId);

        figure
            .then(
                (data) => {
                    setFigure(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        const name = nameRef.current.value;
        const selectedGrip = selectGripRef.current.value === '0' ? null : selectGripRef.current.value;

        const selectedValues = Array.from(selectRef.current.selectedOptions).map(option => option.value);

        createFigure(name, selectedValues, selectedGrip)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const name = nameRef.current.value;
        const selectedGrip = selectGripRef.current.value === '0' ? null : selectGripRef.current.value;
        const selectedValues = Array.from(selectRef.current.selectedOptions).map(option => option.value);

        updateFigure(figure.figureId, name, selectedValues, selectedGrip)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleNameFilterChange = () => {
        setFilterName(nameFilterRef.current.value)
        setPage(1);
    }

    const handleFigureCategoryFilterChange = () => {
        setFilterCategory(selectFilterRef.current.value)
        setPage(1);
    }

    const handleGripFilterChange = () => {
        setFilterGrip(selectGripFilterRef.current.value)
        setPage(1);
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const listHeaders = [
        'Id',
        'Name',
        'Grip',
        'Categories',
        'Actions'
    ];

    const columns = {
        functionParams : ['figureId'],
        dataKeys       : [
            'figureId',
            'name',
            'grip',
            'categories'
        ],
    }

    const figureList = figures.map((item) => {
        const categoryNames = item.categories.map(function (category, index) {
            return category.name;
        }).join(', ');

        return {figureId : item.figureId, grip : item.grip?.name, name : item.name, categories : categoryNames};
    });

    return (
        <Fragment>
            <Box>
                <Button title='Create figure' handleClick={handleCreateButtonClick}/>
                <div className="flex my-4">
                    <input ref={nameFilterRef} type="text" placeholder="Filter by name" className="border p-2 mr-2" onChange={handleNameFilterChange}/>

                    {figureCategories && <select ref={selectFilterRef} onChange={handleFigureCategoryFilterChange} className="border p-2 mr-2">
                        <option value="">Filter by category</option>
                        {
                            figureCategories.map(
                                (option) => (
                                    <option key={option.figureCategoryId} value={option.figureCategoryId}>{option.name}</option>
                                )
                            )
                        }
                    </select>}
                    {grips && <select ref={selectGripFilterRef} onChange={handleGripFilterChange} className="border p-2 mr-2">
                        <option value="">Filter by grip</option>
                        {
                            grips.map(
                                (option) => (
                                    <option key={option.gripId} value={option.gripId}>{option.name}</option>
                                )
                            )
                        }
                    </select>}
                </div>
                <ListEdit
                    headers={listHeaders}
                    items={figureList}
                    columns={columns}
                    pagination={pagination}
                    handleEditIconClick={handleEditIconClick}
                    handlePageChange={handlePageChange}
                    handlePageSizeChange={handlePageSizeChange}
                />
            </Box>
            {showModal && (
                <Modal>
                    <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
                        <ModalForm
                            title={isUpdate ? 'Update figure' : 'Create figure'}
                            saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                            handleCancelButtonClick={() => setShowModal(false)}
                        >
                            <ModalFormInputRow title='Name'>
                                <input
                                    ref={nameRef}
                                    type="text"
                                    id="name"
                                    name="name"
                                    defaultValue={isUpdate ? figure.name : null}
                                    placeholder="Enter name"
                                    className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                                />
                            </ModalFormInputRow>
                            <ModalFormInputRow title='Grip'>
                                {grips && figure && (
                                    <select defaultValue={isUpdate ? figure.grip?.gripId : null} ref={selectGripRef} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                                        <option key={0} value={0}>No Grip</option>
                                        {grips.map((option) => (
                                            <option key={option.gripId} value={option.gripId}>{option.name}</option>
                                        ))}
                                    </select>
                                )}
                            </ModalFormInputRow>
                            <ModalFormInputRow title='Categories'>
                                {figureCategories && figure && <select defaultValue={isUpdate ? figure.categories.map(obj => obj.figureCategoryId) : null} ref={selectRef} multiple={true} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                                    {
                                        figureCategories.map(
                                            (option) => (
                                                <option key={option.figureCategoryId} value={option.figureCategoryId}>{option.name}</option>
                                            )
                                        )
                                    }
                                </select>}
                            </ModalFormInputRow>
                        </ModalForm>
                    </form>
                </Modal>
            )}
        </Fragment>
    );
}

export default FigureList;