import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import Modal from "../UI/Modal";
import React, {useRef} from "react";
import {createCustomerComment, updateCustomerComment} from "../../api/customerApiClient";
import {toast} from "react-toastify";

const CustomerCommentModal = (props) => {
    const isUpdate = props.isUpdate;
    const customerComment = props.customerComment;
    const customerId = props.customerId;
    const handleCancel = props.handleCancel;

    const commentRef = useRef(null);

    const handleAddSubmit = (event) => {
        event.preventDefault();

        const comment = commentRef.current.value;

        createCustomerComment(customerId, comment)
            .then(() => {
                props.handleAddSubmit();
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();

        const comment = commentRef.current.value;

        updateCustomerComment(customerId, customerComment.customerCommentId, comment)
            .then(() => {
                props.handleEditSubmit();
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    return <Modal>
        <form onSubmit={isUpdate ? handleEditSubmit : handleAddSubmit}>
            <ModalForm
                title={isUpdate ? 'Update comment' : 'Add comment'}
                saveButtonTitle={isUpdate ? 'Update' : 'Add'}
                handleCancelButtonClick={handleCancel}
            >
                <ModalFormInputRow title='Comment'>
                    <textarea
                        ref={commentRef}
                        id="comment"
                        name="comment"
                        defaultValue={isUpdate ? customerComment.comment : null}
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
            </ModalForm>
        </form>
    </Modal>
}

export default CustomerCommentModal;