import React, {Fragment} from 'react';
import Box from "../UI/Box";
import UserAssignmentList from "./UserAssignmentList";
import UserView from "./UserView";
import ChangePasswordView from "./ChangePasswordView";

const UserDetails = (props) => {
    return <Fragment>
        <Box>
            <UserView userId={props.userId}/>
        </Box>
        <Box>
            <ChangePasswordView userId={props.userId}/>
        </Box>
        <Box title="User assignments">
            <UserAssignmentList userId={props.userId}/>
        </Box>
    </Fragment>;
}

export default UserDetails;