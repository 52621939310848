import {useEffect, useState} from "react";
import {findFigureCategoriesWithPagination} from "../api/figureApiClient";

export const useFigureCategories = (page, pageSize, reloadTrigger) => {
    const [figureCategories, setFigureCategories] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const figureCategoriesWithPagination = findFigureCategoriesWithPagination(page, pageSize, abortController.signal);

        figureCategoriesWithPagination
            .then(
                (data) => {
                    const figureCategories = data.figureCategories;
                    const pagination = data.pagination;

                    setFigureCategories(figureCategories)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [page, pageSize, reloadTrigger]);

    return {figureCategories : figureCategories, pagination};
};