import {useCallback, useEffect} from "react";
import {getAuthenticatedUser} from "../api/userApiClient";
import {useStateContext} from "../contexts/ContextProvider";

export const useInitAuthenticatedUser = () => {
    const {setUser} = useStateContext();
    const initUser = useCallback(async () => {
        try {
            const data = await getAuthenticatedUser();
            setUser({
                name  : data.name,
                email : data.email,
                type  : data.type,
            })
        }
        catch (error) {
            console.log(error);
        }
    }, [setUser]);

    useEffect(() => {
        const abortController = new AbortController();

        initUser();

        return () => {
            abortController.abort()
        };
    }, [initUser]);

    return;
};