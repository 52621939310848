import {useEffect, useState} from "react";
import {findCustomerLevelRequirementsWithPagination} from "../api/customerApiClient";

export const useCustomerLevelRequirements = (customerId, page, pageSize) => {
    const [customer, setCustomer] = useState(null);
    const [nextLevel, setNextLevel] = useState(null);
    const [customerLevelRequirements, setCustomerLevelRequirements] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const customerLevelRequirementsWithPagination = findCustomerLevelRequirementsWithPagination(customerId, page, pageSize, abortController.signal);

        customerLevelRequirementsWithPagination
            .then(
                (data) => {
                    const customer = data.customer;
                    const nextLevel = data.nextLevel;
                    const customerLevelRequirements = data.customerLevelRequirements;
                    const pagination = data.pagination;

                    setCustomer(customer)
                    setNextLevel(nextLevel)
                    setCustomerLevelRequirements(customerLevelRequirements)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerId, page, pageSize]);

    return {customer : customer, nextLevel : nextLevel, customerLevelRequirements : customerLevelRequirements, pagination};
};