import React, {Fragment, useRef, useState} from 'react';
import Button from "../UI/Button";
import Modal from "../UI/Modal";
import ListEdit from "../UI/ListEdit";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import {createThematic, getThematic, updateThematic} from "../../api/thematicApiClient";
import {useThematics} from "../../hooks/useThematics";
import {useNavigate} from 'react-router-dom';
import Box from "../UI/Box";
import {toast} from "react-toastify";

const ThematicList = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [thematic, setThematic] = useState([]);
    const [submitCount, setSubmitCount] = useState(0);
    const nameRef = useRef(null);
    const {thematics, pagination} = useThematics(page, pageSize, submitCount);
    const navigate = useNavigate();

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePageSizeChange = (pageSize) => {
        setPageSize(pageSize);
        setPage(1);
    };

    const handleEditIconClick = (thematicId) => {
        setIsUpdate(true);
        // @todo Implement abort
        const thematic = getThematic(thematicId);

        thematic
            .then(
                (data) => {
                    setThematic(data)
                    setShowModal(true)
                }
            )
            .catch(
                (error) => console.log(error)
            );
    }

    const handleShowIconClick = (thematicId) => {
        navigate('/thematics/' + thematicId + '/figures');
    }

    const handleCreateButtonClick = (thematicId) => {
        setIsUpdate(false);
        setShowModal(true)
    }

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        const name = nameRef.current.value;

        createThematic(name)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const name = nameRef.current.value;

        updateThematic(thematic.id, name)
            .then(() => {
                setShowModal(false)
                setSubmitCount(prevCount => prevCount + 1);
                toast.success('Success!');
            })
            .catch(
                (error) => {
                    toast.error(error.message);
                }
            );
    }

    const listHeaders = [
        'Id',
        'Name',
        'Actions'
    ];

    const columns = {
        functionParams : ['id'],
        dataKeys       : [
            'id',
            'name'
        ],
    }

    return <Fragment>
        <Box>
            <Button title='Create thematic' handleClick={handleCreateButtonClick}/>
            <ListEdit
                headers={listHeaders}
                items={thematics}
                columns={columns}
                pagination={pagination}
                handleEditIconClick={handleEditIconClick}
                handleShowIconClick={handleShowIconClick}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
            />
        </Box>
        {showModal && (
            <Modal>
                <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
                    <ModalForm
                        title={isUpdate ? 'Update thematic' : 'Create thematic' +
                            ''}
                        saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                        handleCancelButtonClick={() => setShowModal(false)}
                    >
                        <ModalFormInputRow title='Name'>
                            <input
                                ref={nameRef}
                                type="text"
                                id="name"
                                name="name"
                                defaultValue={isUpdate ? thematic.name : null}
                                placeholder="Enter name"
                                className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                            />
                        </ModalFormInputRow>
                    </ModalForm>
                </form>
            </Modal>
        )}
    </Fragment>;
}

export default ThematicList;