import React from "react";
import {useParams} from 'react-router-dom';
import UserDetails from "../../components/user/UserDetails";

const UserDetailPage = () => {
    const {userId} = useParams();

    return <UserDetails userId={userId}/>
}

export default UserDetailPage;