import {useEffect, useState} from "react";
import {findCustomerFiguresWithPagination} from "../api/customerApiClient";

export const useCustomerFigures = (customerId, page, pageSize, filterName, filterStatus, reloadTrigger) => {
    const [customerFigures, setCustomerFigures] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const customerFiguresWithPagination = findCustomerFiguresWithPagination(customerId, page, pageSize, filterName, filterStatus, abortController.signal);

        customerFiguresWithPagination
            .then(
                (data) => {
                    const customerFigures = data.customerFigures;
                    const pagination = data.pagination;

                    setCustomerFigures(customerFigures)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => console.log(error)
            );

        return () => {
            abortController.abort();
        };
    }, [customerId, page, pageSize, filterName, filterStatus, reloadTrigger]);

    return {customerFigures : customerFigures, pagination};
};