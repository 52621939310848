import React, {useRef} from "react";
import Modal from "../UI/Modal";
import ModalForm from "../UI/ModalForm";
import ModalFormInputRow from "../UI/ModalFormInputRow";
import {toast} from "react-toastify";
import {createUser, updateUser} from "../../api/userApiClient";

const UserModal = (props) => {
    const isUpdate = props.isUpdate;
    const handleCancel = props.handleCancel;
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const passwordConfirmationRef = useRef(null);
    const userTypeRef = useRef(null);
    const user = props.user;
    const userTypes = props.userTypes;

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        const passwordConfirmation = passwordConfirmationRef.current.value;
        const userType = userTypeRef.current.value;

        createUser(name, email, password, passwordConfirmation, userType)
            .then(() => {
                props.handleCreateSubmit();
                toast.success('Success!');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    const handleEditSubmit = (event) => {
        event.preventDefault();
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const userType = userTypeRef.current.value;

        updateUser(user.userId, name, email, userType)
            .then(() => {
                props.handleEditSubmit();
                toast.success('Success!');
            })
            .catch((error) => {
                toast.error(error.message);
            });
    }

    return <Modal>
        <form onSubmit={isUpdate ? handleEditSubmit : handleCreateSubmit}>
            <ModalForm
                title={isUpdate ? 'Update user' : 'Create user'}
                saveButtonTitle={isUpdate ? 'Update' : 'Create'}
                handleCancelButtonClick={handleCancel}
            >
                <ModalFormInputRow title='Name'>
                    <input
                        ref={nameRef}
                        type="text"
                        id="name"
                        name="name"
                        defaultValue={isUpdate ? user.name : null}
                        placeholder="Enter name"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
                <ModalFormInputRow title='Email'>
                    <input
                        ref={emailRef}
                        type="email"
                        id="email"
                        name="email"
                        defaultValue={isUpdate ? user.email : null}
                        placeholder="Enter email"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>
                {!isUpdate && <ModalFormInputRow title='Password'>
                    <input
                        ref={passwordRef}
                        type="password"
                        id="password"
                        name="password"
                        defaultValue={isUpdate ? null : null}
                        placeholder="Enter password"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>}
                {!isUpdate && <ModalFormInputRow title='Confirm password'>
                    <input
                        ref={passwordConfirmationRef}
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        defaultValue={isUpdate ? null : null}
                        placeholder="Confirm password"
                        className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline"
                    />
                </ModalFormInputRow>}
                <ModalFormInputRow title='User type'>
                    <select ref={userTypeRef} defaultValue={user.type} className="border rounded-lg py-2 px-3 w-full text-gray-700 focus:outline-none focus:shadow-outline">
                        {userTypes.map((option) => (<option key={option.userTypeId} value={option.name}>{option.name}</option>))}
                    </select>
                </ModalFormInputRow>
            </ModalForm>
        </form>
    </Modal>
}

export default UserModal;