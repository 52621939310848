import React from "react";

const ModalFormInputRow = (props) => {
    return (
        <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">
                {props.title}
            </label>
            {props.children}
        </div>
    );
}

export default ModalFormInputRow;