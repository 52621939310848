import React from "react";

const Button = (props) => {
    const bgColor = props.bgColor ? props.bgColor : 'pink';
    const color = props.color ? props.color : 'white';

    return (
        <button type={props.isSubmit ? 'submit' : 'button'} onClick={props.isSubmit ? null : props.handleClick} className={`bg-${bgColor}-500 hover:bg-${bgColor}-700 text-${color} font-bold py-2 px-4 rounded mx-1`}>
            {props.title}
        </button>
    );
}

export default Button;