import {useEffect, useState} from "react";
import {findExamsWithPagination} from "../api/examApiClient";

export const useExams = (page, pageSize, reloadTrigger) => {
    const [exams, setExams] = useState([]);
    const [pagination, setPagination] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();
        const examsWithPagination = findExamsWithPagination(page, pageSize, abortController.signal);

        examsWithPagination
            .then(
                (data) => {
                    const exams = data.exams;
                    const pagination = data.pagination;

                    setExams(exams)
                    setPagination(pagination)
                }
            )
            .catch(
                (error) => {
                    if (error.name === "AbortError") {
                        console.log("Aborted");
                    }
                    else {
                        console.log(error);
                    }
                }
            );

        return () => {
            abortController.abort();
        };
    }, [page, pageSize, reloadTrigger]);

    return {exams : exams, pagination};
};