import React from "react";
import {useParams} from 'react-router-dom';
import ExamRequirementsList from "../../components/exam/ExamRequirementsList";

const ExamRequirementsPage = () => {
    const {examId} = useParams();

    return <ExamRequirementsList examId={examId}/>
}

export default ExamRequirementsPage;